import React, { useState } from 'react'
import { LargeLabel } from '../../templates/label/Labels'
import { GenericElement } from '../../templates/FormElement'
import { RefUserAttributesHook } from '../hooks/UserAttributesHook';
import ResultsSummary from './ResultsSummary';
import InputsSummary from './InputsSummary';
import PurchaseReport from './PurchaseReport';
import PositionReport from './PositionReport';
import ForwardAssessmentReport from './ForwardAssessmentReport';



// Global constants
import * as con from '../../GlobalConstants'
import logo from "./../../img/logo_lumen_dark.png"
import { IsStaff } from '../hooks/AuthenticationHook';
import CommitteeReport from './CommitteeReport';
import AllManagementReport from './AllManagementReport';


const containerStyle = {
    display: 'flex',
    height: con.USABLE_SCREEN_SIZE + "vh",
  };

const logoSlideStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-evenly",
    width: "25%",
    height: "100%"
}

const logoStyle = {
    marginLeft : "50px",
    marginRight : "2px",
};

const logoStyle1 = {
    marginLeft : "50px",
    marginRight : "2px",
    maxWidth : "70%"
};    

const reportSlideStyle = {
    width: "100%",
    height: "100%"
}

const ReportSelector = ({report}) =>{

    switch(report){
        case con.BAND_SUMMARY_REPORT:
            return <ResultsSummary/>
        case con.CURRENT_FLOW_REPORT:
            return <InputsSummary/>
        case con.PURCHASE_REPORT:
            return <PurchaseReport/>
        case con.POSITION_REPORT:
            return <PositionReport/>
        case con.ASSESSMENT_FWD_REPORT:
            return <ForwardAssessmentReport/>
        case con.COMMITTEE_REPORT:
            return <CommitteeReport/>
        case con.ALL_MANAGEMENT_REPORT:
            return <AllManagementReport/>
        default:
            return null

    }
}

const availableReports = (isStaff) =>{
    const reportKeys = [];
    const reportNames = [];

    for (const key in con.AVAILABLE_REPORTS) {
        if(isStaff || !(con.AVAILABLE_REPORTS[key][con.STAFF])){
            reportKeys.push(key);
            reportNames.push(con.AVAILABLE_REPORTS[key][con.NAME])
        }
    }
    return {reportKeys, reportNames};
}


function ReportMenu() {
    const [selectedReport, setSelectedReport] = useState(() => "")
    const avatarImage = RefUserAttributesHook(con.AVATAR_IMAGE)
    console.log(avatarImage)
    const { reportKeys, reportNames } = availableReports(IsStaff())

    return (
        <div style={containerStyle}>
            <div style={logoSlideStyle}>
                <img src={avatarImage} alt="Logo" style={logoStyle}/>
                <img src={logo} alt="Logo" style={logoStyle1}/>
            </div>
            <div style={reportSlideStyle}>
                <LargeLabel text="DESCARGAR REPORTES"></LargeLabel>

                < GenericElement type={con.MULTIPLE_SELECTION}
                                name={'¿Qué reporte desea descargar?'}
                                // id={}               // Id not necessary  REPORT MENU
                                value={selectedReport} 
                                setValue={setSelectedReport} 
                                values={reportKeys}
                                valueLabels={reportNames}
                                /> 
                <ReportSelector report={selectedReport} ></ReportSelector>
            </div>
        </div>
    )
}

export default ReportMenu