// Collection of transaction functions

/**
 * Module of functions in charge of transaction consolidation and manipulation.
 * All table operations are managed using Denfo.js
 */

// import Tensor Flow
import * as tf from "@tensorflow/tfjs"

// Import Danfo
import * as dfd from "danfojs"



// Import date functions
import * as dateFun from "./../utils/dateFunctions"  

// Global constants
import * as con from "./../GlobalConstants"

import { getBandsConstructionFunction, getSummaryBands } from "./bandFunctions";

import { getDefaultValue} from '../templates/Types'
import { average } from "../GlobalFunctions";
import { counterpartiesNames, translateParameter } from "./translateFunctions";
import { getApplicationParameter } from "../store/actions/applicationParameters";
import { addMonths } from '../utils/dateFunctions'
import { getOptionAssessmentData } from "./optionFunctions";

// Epsylon
const epsylon = 1e-14



// Transactions with summary
export const processTransactionsWithSummary = async (currentDate,
                                            spot,
                                            scenarioGenerationFun,
                                            modelParameters, 
                                            exposures,
                                            coveragesForward,
                                            optionCoverages,
                                            accounts,
                                            modelApplicationParameters) =>
{        
        // Adds aditional options info (like delta)
        const option_list = Object.values(optionCoverages)
        const referenceDate = currentDate
        const option_assessment_rate = spot
        let options = await getOptionAssessmentData({option_list, referenceDate, option_assessment_rate})
        options = options[con.DATA][con.DATA] !== undefined ? options[con.DATA][con.DATA][con.COVERAGES_OPTION] : undefined

        let df_trans = executeProcessTransactions(currentDate, spot,scenarioGenerationFun, modelParameters, exposures, coveragesForward, options, accounts, modelApplicationParameters)
        let resp = []
        let summary = {}


        if(df_trans !== null)
        {   
            resp = dfd.toJSON(df_trans, { download: false }) 
            resp.forEach((ob,i) => ob[con.ID] = i)
            summary = await createSummary(df_trans, modelParameters)                    
        }

        return([resp, summary])
}




// Method that gets total vars
export const getTotalVars = async (currentDate, 
                                    spot,
                                    scenarioGenerationFun,
                                    modelParameters, 
                                    exposures,
                                    coveragesForward,
                                    optionCoverages,
                                    accounts,
                                    modelApplicationParameters) =>
{

// Declares the var columns
let varColumns = ['var'].concat(con.BANDS.map((b) => `${b}_${con.VAR}`))

// Extracts transactions summary
let trans_df = executeProcessTransactions(currentDate,spot,scenarioGenerationFun, modelParameters, exposures, coveragesForward, optionCoverages, accounts, modelApplicationParameters)

if(trans_df === null)
    return(null)
    
return( Object.assign({}, ...varColumns.map((col) => ({[col]: trans_df[col].sum()}))))


}

export const sortTransactionKeysByColumn = (transactionsDic, col, order) =>
{
    let keys = Object.keys(transactionsDic)
    if(col === null)
        return(keys)

    keys = keys.sort((k1, k2) => {
        if (transactionsDic[k1][col] < transactionsDic[k2][col]) {
            return(order === con.ASCENDING ? -1 : 1)
          }
          if (transactionsDic[k1][col] > transactionsDic[k2][col]) {
            return(order === con.ASCENDING ? 1 : -1)
          }
            return(0)
        });

    return(keys)
}

export const selectTransactionsByPage = (transArray, page) =>
{
    return(transArray.slice(page*con.MAX_ROWS_PER_PAGE, Math.min(transArray.length, (page+1)*con.MAX_ROWS_PER_PAGE)))
}

export const getTotalTransactionPages = (totalTransactions) =>
{
    return(Math.floor(Math.max(0,totalTransactions -1)/con.MAX_ROWS_PER_PAGE))
}


/**
 * Main method
 * The current flow to consolidate the transactions is as follows:
 * 1. Filters by only active exposures and coverages
 * 2. Merge exposures and forward coverages into a single DataFrame
 * 3. Uses the money in accounts to remove first exposures
 * 4. Attaches the dollar value (VaR) to the data Frame
 * 5. Takes dates to the end of the desired periodicity
 * 6. Groups transactions by date
 * 7. Applies the over coverage scheme
 * 8. Constructs Opportunistic VaR 
 * 9. Constructs VaR by Band
 * 10. Groups summary table by Month
 * 11. Attaches Position in bands
 */
const executeProcessTransactions = (current_date,
                                    spot,
                                    scenarioGenerationFun,
                                    modelParameters, 
                                    exposures,
                                    coveragesForward,
                                    optionCoverages,
                                    accounts,
                                    modelApplicationParameters) =>
{


    if(Object.keys(accounts).length === 0)
        return(createEmptyDataFrame())

    // Extracts variables
    // Model
    let clientType = modelParameters[con.CLIENT_TYPE]
    let periodicity = modelParameters[con.PERIODICITY]
    let term_periodicity = modelParameters[con.TERM_PERIODICITY]   
    let average_for_percentage = modelParameters[con.AVERAGE_FOR_PERCENTAGE]     
    let bands = modelParameters[con.MID_BAND_VALUES]
    let bands_with = modelParameters[con.BAND_WIDTH]
    let passthrough = modelParameters[con.PASSTHROUGH]  

    let [term_in_months, monthly_bands] = adjustBandToPeriodicity(periodicity, term_periodicity, current_date, bands)
    
    // Application
    let carryOverCoverage = modelApplicationParameters[con.CARRY_OVER_COVERAGE]
    let visualizationPeriodicity = modelApplicationParameters[con.VISUALIZATION_PERIODICITY]


    // Filters
    exposures = filterExposures(current_date, term_in_months, exposures)
    let [directCoveragesForward, counterCoveragesForward] = filterFWDCoverages(current_date, term_in_months, clientType, coveragesForward)
    let [directCall, directPut, counterBuyCall, counterSellCall, counterBuyPut, counterSellPut] = filterOptionCoverages(current_date, term_in_months, optionCoverages, clientType)

    if(exposures.length === 0 && directCoveragesForward.length === 0 && counterCoveragesForward.length === 0 &&
        directCall.length === 0 && directPut.length === 0 && counterBuyCall.length === 0 && counterSellCall.length === 0 &&
        counterBuyPut.length === 0 && counterSellPut.length === 0)
            return(createEmptyDataFrame())

    // Adds mock exposure and coverages (so that the table is computed)
    exposures.push(createMockExposure(current_date))
    directCoveragesForward.push(createMockFwdCoverage(current_date))
    counterCoveragesForward.push(createMockFwdCoverage(current_date))
    directCall.push(createMockOptionCoverage(current_date))
    directPut.push(createMockOptionCoverage(current_date))
    counterBuyCall.push(createMockOptionCoverage(current_date))
    counterSellCall.push(createMockOptionCoverage(current_date))
    counterBuyPut.push(createMockOptionCoverage(current_date))
    counterSellPut.push(createMockOptionCoverage(current_date))

    // Merges
    let trans_df = mergeExposuresAndCoverages(clientType, current_date, periodicity, term_in_months, exposures, directCoveragesForward, counterCoveragesForward,
                                              directCall, directPut, counterBuyCall, counterSellCall, counterBuyPut, counterSellPut, spot)                      

    // Removes exposures with account balances
    trans_df = removeExposures(trans_df, accounts)

    // Attaches scenario 
    trans_df = attachDollarScenario(current_date, spot, trans_df, scenarioGenerationFun) 

    // Consolidate options and VaR
    trans_df = optionVARConsolidation(trans_df)

    // Adjusts periodicity
    trans_df = adjustPeriodicity(trans_df, periodicity)

    // Groups  
    trans_df = groupByDate(trans_df) 


    // Over coverage
    if(carryOverCoverage)
        trans_df = applyOverCoverageScheme(trans_df) 


    // LEGACY
    // // Attaches real_position
    // trans_df = attachRealPosition(trans_df)  


    // Adds the opportunistic Var
    trans_df = addOpportunisticVar(trans_df, spot, clientType, passthrough)  

    
    // Adds Bands
    trans_df = addVarByBands(current_date, trans_df, spot, clientType, average_for_percentage, monthly_bands, bands_with)
        
    // Converts periodicity to month
    if(visualizationPeriodicity !== periodicity)
    {
        trans_df = adjustPeriodicity(trans_df, visualizationPeriodicity)
        trans_df = groupByDate(trans_df)   
    }


    trans_df = attachPositionInBands(trans_df)

    // Adds Period
    trans_df = trans_df.addColumn(con.PERIOD, [...Array(trans_df.shape[0]).keys()])
    
    return(trans_df)

}

const adjustBandToPeriodicity = (periodicity, term_periodicity, current_date, bands) => {
    
    let copyBands = bands.slice();     // Arrays pass as reference
    let term_in_months
    let monthly_bands
    const dateRange = [];
    const newBands = [];
    const lastBand = copyBands[copyBands.length - 1]
    let endDate
    
    switch (periodicity) {
    case con.PERIODICITY_WEEK:
        term_in_months = Math.ceil(term_periodicity / 4)
        endDate = addMonths(current_date, term_in_months);
        while(current_date <= endDate){
            dateRange.push(current_date);
            if (current_date.weekday === 1) {
                newBands.push(copyBands[0] !== undefined ? copyBands.shift() : lastBand); 
              } else {
                const band = newBands.length > 0 ? newBands[newBands.length - 1] : copyBands[0]
                newBands.push(band !== undefined ? band : lastBand);
              }
              current_date = dateFun.addDays(current_date, 1, true)
        }
        break;
    case con.PERIODICITY_DAY:
        term_in_months = Math.ceil(term_periodicity / 30)
        endDate = addMonths(current_date, term_in_months);
        while(current_date <= endDate){
            dateRange.push(current_date);
            newBands.push(copyBands[0] !== undefined ? copyBands.shift() : lastBand); 
            current_date = dateFun.addDays(current_date, 1, true)
        }
        break;
    case con.PERIODICITY_MONTH:
        term_in_months = term_periodicity
        monthly_bands = bands
        break;

    default:       
        throw new Error(`Periodicity not supported: ${periodicity}`)     
    }
       
    let all_bands = dateRange.map((d, i) => ({[con.DATE] : dateFun.formatDate(d), "band" : newBands[i]}))    
    all_bands = new dfd.DataFrame(all_bands);

    if(all_bands.shape[0] > 0){
        all_bands = adjustPeriodicity(all_bands, con.PERIODICITY_MONTH)
        all_bands = all_bands.groupby([con.DATE]).mean()
        monthly_bands = all_bands["band_mean"].values
    }
    
    return ([term_in_months, monthly_bands])    
}



const filterExposures = (current_date, term_in_months, exposures) =>
{

    // Extracts Range
    let min_date = current_date
    let max_date = dateFun.takeDateToEndOfPeriodicity(dateFun.addMonths(current_date,term_in_months), con.PERIODICITY_MONTH)

    // Removes non active exposures
    exposures = Object.values(exposures).filter((row) => dateFun.parseDate(row.expiration_date) > min_date & dateFun.parseDate(row.expiration_date) <= max_date)                                         


    return(exposures)

}

const filterCoveragesByDateRange = (current_date, term_in_months, coverages) => {
    // Extracts Range
    let min_date = current_date
    let max_date = dateFun.takeDateToEndOfPeriodicity(dateFun.addMonths(current_date,term_in_months), con.PERIODICITY_MONTH)

    // Removes non active coverages
    if (coverages === undefined) {
        coverages = {}
    }
    coverages = Object.values(coverages).filter((row) => dateFun.parseDate(row.expiration_date) > min_date & dateFun.parseDate(row.expiration_date) <= max_date)
    
    return coverages
}


const filterFWDCoverages = (current_date, term_in_months, clientType, fwdCoverages) =>
{

    fwdCoverages = filterCoveragesByDateRange(current_date, term_in_months, fwdCoverages)

    let directType = clientType === con.IMPORTER ? con.BUY : con.SELL
    let indirectType = clientType === con.IMPORTER ? con.SELL : con.BUY
    
    let directCoverages = fwdCoverages.filter(ob => ob[con.COVERAGE_TYPE] === directType)
    let counterCoverages = fwdCoverages.filter(ob => ob[con.COVERAGE_TYPE] === indirectType)


    return([ directCoverages, counterCoverages])

}


const filterOptionCoverages = (current_date, term_in_months, optionCoverages, clientType) => {
    optionCoverages = filterCoveragesByDateRange(current_date, term_in_months, optionCoverages);

    let directCall = [];
    let directPut = [];
    let counterBuyCall = [];
    let counterSellCall = [];
    let counterBuyPut = [];
    let counterSellPut = [];

    optionCoverages.forEach(ob => {
        if (clientType === con.IMPORTER) {
            if (ob[con.OPTION_TYPE] === con.CALL && ob[con.COVERAGE_TYPE] === con.BUY) {
                directCall.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.CALL && ob[con.COVERAGE_TYPE] === con.SELL) {
                counterSellCall.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.PUT && ob[con.COVERAGE_TYPE] === con.BUY) {
                counterBuyPut.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.PUT && ob[con.COVERAGE_TYPE] === con.SELL) {
                counterSellPut.push(ob);
            }
        } else if (clientType === con.EXPORTER) {
            if (ob[con.OPTION_TYPE] === con.PUT && ob[con.COVERAGE_TYPE] === con.BUY) {
                directPut.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.CALL && ob[con.COVERAGE_TYPE] === con.BUY) {
                counterBuyCall.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.CALL && ob[con.COVERAGE_TYPE] === con.SELL) {
                counterSellCall.push(ob);
            } else if (ob[con.OPTION_TYPE] === con.PUT && ob[con.COVERAGE_TYPE] === con.SELL) {
                counterSellPut.push(ob);
            }
        }
    });

    return([directCall, directPut, counterBuyCall, counterSellCall, counterBuyPut, counterSellPut]);
};

const mergeExposuresAndCoverages = (clientType, current_date, periodicity, term_in_months, exposures, directCoverages, counterCoverages,
                                    directCall, directPut, counterBuyCall, counterSellCall, counterBuyPut, counterSellPut, spot) =>
{

    // Option Cost (Premium Gains direction)
    const sign = clientType === con.EXPORTER ? 1 : -1

    // Extracts Range
    let min_date = current_date
    let max_date = dateFun.takeDateToEndOfPeriodicity(dateFun.addMonths(current_date,term_in_months), con.PERIODICITY_MONTH)

    // Creates Data Frame to fill missing dates
    let all_dates = dateFun.getDatesBetween(min_date,max_date, periodicity)


    // Exposures
    // -------------------

    // Extract and renames
    exposures = exposures.map((row) => {return({date : row.expiration_date, exposure_amount : row.amount})});
                                            
    // Adds Possible dates
    exposures = exposures.concat(all_dates.map(d => {return({date : dateFun.formatDate(d), exposure_amount : 0})}))


    // Convert to data Frame
    exposures = new dfd.DataFrame(exposures);


    // GroupsBy
    exposures = exposures.groupby([con.DATE]).sum().rename({[`${con.EXPOSURE_AMOUNT}_sum`]: con.EXPOSURE_AMOUNT});

    // Direct Coverages
    // -------------------
    // Extract and renames
    directCoverages = directCoverages.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.FWD_COVERAGE_AMOUNT] : row[con.AMOUNT], [con.FWD_COVERAGE_RATE] :  row[con.RATE]})});
                   
    directCoverages = new dfd.DataFrame(directCoverages)

    // GroupsBy
    // Multiplies by coverage
    directCoverages = setColumn(directCoverages, con.FWD_COVERAGE_RATE, directCoverages[con.FWD_COVERAGE_RATE].mul(directCoverages[con.FWD_COVERAGE_AMOUNT]))

    // Merges
    directCoverages = directCoverages.groupby([con.DATE]).sum().rename({ [`${con.FWD_COVERAGE_AMOUNT}_sum`]: con.FWD_COVERAGE_AMOUNT,
                                                            [`${con.FWD_COVERAGE_RATE}_sum`]: con.FWD_COVERAGE_RATE});
    // Divides by totals    
    directCoverages = setColumn(directCoverages, con.FWD_COVERAGE_RATE, directCoverages[con.FWD_COVERAGE_RATE].div(directCoverages[con.FWD_COVERAGE_AMOUNT]))


    // Counter Coverages
    // -------------------
    // Extract and renames
    counterCoverages = counterCoverages.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.FWD_COUNTER_COVERAGE_AMOUNT] : row[con.AMOUNT], [con.FWD_COUNTER_RATE] :  row[con.RATE]})});
                   
    counterCoverages = new dfd.DataFrame(counterCoverages)

    // GroupsBy
    // Multiplies by coverage
    counterCoverages = setColumn(counterCoverages, con.FWD_COUNTER_RATE, counterCoverages[con.FWD_COUNTER_RATE].mul(counterCoverages[con.FWD_COUNTER_COVERAGE_AMOUNT]))

    // Merges
    counterCoverages = counterCoverages.groupby([con.DATE]).sum().rename({ [`${con.FWD_COUNTER_COVERAGE_AMOUNT}_sum`]: con.FWD_COUNTER_COVERAGE_AMOUNT,
                                                            [`${con.FWD_COUNTER_RATE}_sum`]: con.FWD_COUNTER_RATE});
    // Divides by totals    
    counterCoverages = setColumn(counterCoverages, con.FWD_COUNTER_RATE, counterCoverages[con.FWD_COUNTER_RATE].div(counterCoverages[con.FWD_COUNTER_COVERAGE_AMOUNT]))


    // Direct Call Coverages
    // -------------------
    // Extract and renames
    directCall = directCall.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.DIRECT_CALL_DELTA_AMOUNT] : row[con.COVERAGES], 
                                                 [con.DIRECT_CALL_AMOUNT] : row[con.AMOUNT], [con.DIRECT_CALL_STRIKE] :  row[con.STRIKE],
                                                 [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});
                   
    directCall = new dfd.DataFrame(directCall)

    // GroupsBy
    // Multiplies by coverage
    directCall = setColumn(directCall, con.DIRECT_CALL_STRIKE, directCall[con.DIRECT_CALL_STRIKE].mul(directCall[con.DIRECT_CALL_DELTA_AMOUNT]))

    // Merges
    directCall = directCall.groupby([con.DATE]).sum().rename({ [`${con.DIRECT_CALL_DELTA_AMOUNT}_sum`]: con.DIRECT_CALL_DELTA_AMOUNT,
                                                               [`${con.DIRECT_CALL_AMOUNT}_sum`]: con.DIRECT_CALL_AMOUNT,
                                                               [`${con.DIRECT_CALL_STRIKE}_sum`]: con.DIRECT_CALL_STRIKE,
                                                               [`${con.PREMIUM_GAINS}_sum`]: con.PREMIUM_GAINS});
    // Divides by totals    
    directCall = setColumn(directCall, con.DIRECT_CALL_STRIKE, directCall[con.DIRECT_CALL_STRIKE].div(directCall[con.DIRECT_CALL_DELTA_AMOUNT]))

    // Counter Buy Call Coverages
    // -------------------
    // Extract and renames
    counterBuyCall = counterBuyCall.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.COUNTER_BUY_CALL_DELTA_AMOUNT] : row[con.COVERAGES],
                                                    [con.COUNTER_BUY_CALL_AMOUNT] : row[con.AMOUNT], [con.COUNTER_BUY_CALL_STRIKE] :  row[con.STRIKE],
                                                    [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});

    counterBuyCall = new dfd.DataFrame(counterBuyCall)

    // GroupsBy
    // Multiplies by coverage
    counterBuyCall = setColumn(counterBuyCall, con.COUNTER_BUY_CALL_STRIKE, counterBuyCall[con.COUNTER_BUY_CALL_STRIKE].mul(counterBuyCall[con.COUNTER_BUY_CALL_DELTA_AMOUNT]))

    // Merges
    counterBuyCall = counterBuyCall.groupby([con.DATE]).sum().rename({ [`${con.COUNTER_BUY_CALL_AMOUNT}_sum`]: con.COUNTER_BUY_CALL_AMOUNT,
                                                                       [`${con.COUNTER_BUY_CALL_DELTA_AMOUNT}_sum`]: con.COUNTER_BUY_CALL_DELTA_AMOUNT,
                                                                       [`${con.COUNTER_BUY_CALL_STRIKE}_sum`]: con.COUNTER_BUY_CALL_STRIKE,
                                                                       [`${con.PREMIUM_GAINS}_sum`]: `${con.PREMIUM_GAINS}_1`});
    // Divides by totals    
    counterBuyCall = setColumn(counterBuyCall, con.COUNTER_BUY_CALL_STRIKE, counterBuyCall[con.COUNTER_BUY_CALL_STRIKE].div(counterBuyCall[con.COUNTER_BUY_CALL_DELTA_AMOUNT]))


    // Counter Sell Call Coverages
    // -------------------
    // Extract and renames
    counterSellCall = counterSellCall.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.COUNTER_SELL_CALL_DELTA_AMOUNT] : row[con.COVERAGES],
        [con.COUNTER_SELL_CALL_AMOUNT] : row[con.AMOUNT], [con.COUNTER_SELL_CALL_STRIKE] :  row[con.STRIKE],
        [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});

    counterSellCall = new dfd.DataFrame(counterSellCall)

    // GroupsBy
    // Multiplies by coverage
    counterSellCall = setColumn(counterSellCall, con.COUNTER_SELL_CALL_STRIKE, counterSellCall[con.COUNTER_SELL_CALL_STRIKE].mul(counterSellCall[con.COUNTER_SELL_CALL_DELTA_AMOUNT]))

    // Merges
    counterSellCall = counterSellCall.groupby([con.DATE]).sum().rename({ [`${con.COUNTER_SELL_CALL_AMOUNT}_sum`]: con.COUNTER_SELL_CALL_AMOUNT,
                                                                         [`${con.COUNTER_SELL_CALL_DELTA_AMOUNT}_sum`]: con.COUNTER_SELL_CALL_DELTA_AMOUNT,
                                                                         [`${con.COUNTER_SELL_CALL_STRIKE}_sum`]: con.COUNTER_SELL_CALL_STRIKE,
                                                                         [`${con.PREMIUM_GAINS}_sum`]: `${con.PREMIUM_GAINS}_2`});
    // Divides by totals    
    counterSellCall = setColumn(counterSellCall, con.COUNTER_SELL_CALL_STRIKE, counterSellCall[con.COUNTER_SELL_CALL_STRIKE].div(counterSellCall[con.COUNTER_SELL_CALL_DELTA_AMOUNT]))

    // Direct Put Coverages
    // -------------------
    // Extract and renames
    directPut = directPut.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.DIRECT_PUT_DELTA_AMOUNT] : row[con.COVERAGES],
                                                [con.DIRECT_PUT_AMOUNT] : row[con.AMOUNT], [con.DIRECT_PUT_STRIKE] :  row[con.STRIKE],
                                                [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});

    directPut = new dfd.DataFrame(directPut)

    // GroupsBy
    // Multiplies by coverage
    directPut = setColumn(directPut, con.DIRECT_PUT_STRIKE, directPut[con.DIRECT_PUT_STRIKE].mul(directPut[con.DIRECT_PUT_DELTA_AMOUNT]))

    // Merges
    directPut = directPut.groupby([con.DATE]).sum().rename({ [`${con.DIRECT_PUT_AMOUNT}_sum`]: con.DIRECT_PUT_AMOUNT,
                                                             [`${con.DIRECT_PUT_DELTA_AMOUNT}_sum`]: con.DIRECT_PUT_DELTA_AMOUNT,
                                                             [`${con.DIRECT_PUT_STRIKE}_sum`]: con.DIRECT_PUT_STRIKE,
                                                             [`${con.PREMIUM_GAINS}_sum`]: `${con.PREMIUM_GAINS}_3`});
    // Divides by totals    
    directPut = setColumn(directPut, con.DIRECT_PUT_STRIKE, directPut[con.DIRECT_PUT_STRIKE].div(directPut[con.DIRECT_PUT_DELTA_AMOUNT]))


    // Counter Buy Put Coverages
    // -------------------
    // Extract and renames
    counterBuyPut = counterBuyPut.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.COUNTER_BUY_PUT_DELTA_AMOUNT] : row[con.COVERAGES],
                                                 [con.COUNTER_BUY_PUT_AMOUNT] : row[con.AMOUNT], [con.COUNTER_BUY_PUT_STRIKE] :  row[con.STRIKE],
                                                 [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});

    counterBuyPut = new dfd.DataFrame(counterBuyPut)

    // GroupsBy
    // Multiplies by coverage
    counterBuyPut = setColumn(counterBuyPut, con.COUNTER_BUY_PUT_STRIKE, counterBuyPut[con.COUNTER_BUY_PUT_STRIKE].mul(counterBuyPut[con.COUNTER_BUY_PUT_DELTA_AMOUNT]))

    // Merges
    counterBuyPut = counterBuyPut.groupby([con.DATE]).sum().rename({ [`${con.COUNTER_BUY_PUT_AMOUNT}_sum`]: con.COUNTER_BUY_PUT_AMOUNT,
                                                                     [`${con.COUNTER_BUY_PUT_DELTA_AMOUNT}_sum`]: con.COUNTER_BUY_PUT_DELTA_AMOUNT,
                                                                     [`${con.COUNTER_BUY_PUT_STRIKE}_sum`]: con.COUNTER_BUY_PUT_STRIKE,
                                                                     [`${con.PREMIUM_GAINS}_sum`]: `${con.PREMIUM_GAINS}_4`});
    // Divides by totals    
    counterBuyPut = setColumn(counterBuyPut, con.COUNTER_BUY_PUT_STRIKE, counterBuyPut[con.COUNTER_BUY_PUT_STRIKE].div(counterBuyPut[con.COUNTER_BUY_PUT_DELTA_AMOUNT]))   

    // Counter Sell Put Coverages    
    // -------------------
    // Extract and renames
    counterSellPut = counterSellPut.map((row) => {return({date : row[con.EXPIRATION_DATE], [con.COUNTER_SELL_PUT_DELTA_AMOUNT] : row[con.COVERAGES],
                                                    [con.COUNTER_SELL_PUT_AMOUNT] : row[con.AMOUNT], [con.COUNTER_SELL_PUT_STRIKE] :  row[con.STRIKE],
                                                    [con.PREMIUM_GAINS] : row[con.PREMIUM_GAINS] * sign})});

    counterSellPut = new dfd.DataFrame(counterSellPut)

    // GroupsBy
    // Multiplies by coverage
    counterSellPut = setColumn(counterSellPut, con.COUNTER_SELL_PUT_STRIKE, counterSellPut[con.COUNTER_SELL_PUT_STRIKE].mul(counterSellPut[con.COUNTER_SELL_PUT_DELTA_AMOUNT]))

    // Merges
    counterSellPut = counterSellPut.groupby([con.DATE]).sum().rename({ [`${con.COUNTER_SELL_PUT_AMOUNT}_sum`]: con.COUNTER_SELL_PUT_AMOUNT,
                                                                       [`${con.COUNTER_SELL_PUT_DELTA_AMOUNT}_sum`]: con.COUNTER_SELL_PUT_DELTA_AMOUNT,
                                                                       [`${con.COUNTER_SELL_PUT_STRIKE}_sum`]: con.COUNTER_SELL_PUT_STRIKE,
                                                                       [`${con.PREMIUM_GAINS}_sum`]: `${con.PREMIUM_GAINS}_5`});
    // Divides by totals    
    counterSellPut = setColumn(counterSellPut, con.COUNTER_SELL_PUT_STRIKE, counterSellPut[con.COUNTER_SELL_PUT_STRIKE].div(counterSellPut[con.COUNTER_SELL_PUT_DELTA_AMOUNT]))   


    // Merges all jsons into a single DataFrame
    let trans_df = dfd.merge({ left: exposures, right: directCoverages, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: counterCoverages, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: directCall, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: counterBuyCall, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: counterSellCall, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: directPut, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: counterBuyPut, on: [con.DATE], how: "outer"});
    trans_df = dfd.merge({ left: trans_df, right: counterSellPut, on: [con.DATE], how: "outer"});
  
    // Consolidate Coverages
    // -------------------
    // Option Coverage Amount
    trans_df = trans_df.fillNa([0,0], {columns : [ con.DIRECT_CALL_DELTA_AMOUNT, con.DIRECT_PUT_DELTA_AMOUNT]});
    trans_df = trans_df.addColumn(con.OPTION_COVERAGE_AMOUNT, trans_df[con.DIRECT_CALL_DELTA_AMOUNT].add(trans_df[con.DIRECT_PUT_DELTA_AMOUNT]))

    // Premium Gains (Option cost)
    trans_df = trans_df.fillNa([0,0,0,0,0,0], {columns : [ con.PREMIUM_GAINS, `${con.PREMIUM_GAINS}_1`, `${con.PREMIUM_GAINS}_2`, `${con.PREMIUM_GAINS}_3`, `${con.PREMIUM_GAINS}_4`, `${con.PREMIUM_GAINS}_5`]});
    trans_df = setColumn(trans_df, con.PREMIUM_GAINS, trans_df[con.PREMIUM_GAINS].add(trans_df[`${con.PREMIUM_GAINS}_1`]))
    trans_df = setColumn(trans_df, con.PREMIUM_GAINS, trans_df[con.PREMIUM_GAINS].add(trans_df[`${con.PREMIUM_GAINS}_2`]))
    trans_df = setColumn(trans_df, con.PREMIUM_GAINS, trans_df[con.PREMIUM_GAINS].add(trans_df[`${con.PREMIUM_GAINS}_3`]))
    trans_df = setColumn(trans_df, con.PREMIUM_GAINS, trans_df[con.PREMIUM_GAINS].add(trans_df[`${con.PREMIUM_GAINS}_4`]))
    trans_df = setColumn(trans_df, con.PREMIUM_GAINS, trans_df[con.PREMIUM_GAINS].add(trans_df[`${con.PREMIUM_GAINS}_5`]))

    
    // Option Rate (One of them must be 0, so I can just sum)
    trans_df = trans_df.fillNa([0,0], {columns : [ con.DIRECT_CALL_STRIKE, con.DIRECT_PUT_STRIKE]});
    trans_df = trans_df.addColumn(con.OPTION_COVERAGE_RATE, trans_df[con.DIRECT_CALL_STRIKE].add(trans_df[con.DIRECT_PUT_STRIKE]))


    // Total Coverage Amount
    trans_df = trans_df.fillNa([0,0], {columns : [ con.FWD_COVERAGE_AMOUNT, con.FWD_COVERAGE_RATE]});
    trans_df = trans_df.addColumn(con.COVERAGE_AMOUNT, trans_df[con.OPTION_COVERAGE_AMOUNT].add(trans_df[con.FWD_COVERAGE_AMOUNT]))


    // Calculate RATE
    let fwd_cov_amount = trans_df[con.FWD_COVERAGE_AMOUNT].fillNa(0)
    let fwd_cov_rate = trans_df[con.FWD_COVERAGE_RATE].fillNa(0)
    let fwd_counter_amount = trans_df[con.FWD_COUNTER_COVERAGE_AMOUNT].fillNa(0)
    let fwd_counter_rate = trans_df[con.FWD_COUNTER_RATE].fillNa(0)
    let direct_call_delta_amount = trans_df[con.DIRECT_CALL_DELTA_AMOUNT].fillNa(0)
    let direct_call_strike = trans_df[con.DIRECT_CALL_STRIKE].fillNa(0)
    let direct_put_delta_amount = trans_df[con.DIRECT_PUT_DELTA_AMOUNT].fillNa(0)
    let direct_put_strike = trans_df[con.DIRECT_PUT_STRIKE].fillNa(0)
    let counter_buy_call_delta_amount = trans_df[con.COUNTER_BUY_CALL_DELTA_AMOUNT].fillNa(0)
    let counter_buy_call_strike = trans_df[con.COUNTER_BUY_CALL_STRIKE].fillNa(0)
    let counter_sell_call_delta_amount = trans_df[con.COUNTER_SELL_CALL_DELTA_AMOUNT].fillNa(0)
    let counter_sell_call_strike = trans_df[con.COUNTER_SELL_CALL_STRIKE].fillNa(0)
    let counter_buy_put_delta_amount = trans_df[con.COUNTER_BUY_PUT_DELTA_AMOUNT].fillNa(0)
    let counter_buy_put_strike = trans_df[con.COUNTER_BUY_PUT_STRIKE].fillNa(0)
    let counter_sell_put_delta_amount = trans_df[con.COUNTER_SELL_PUT_DELTA_AMOUNT].fillNa(0)
    let counter_sell_put_strike = trans_df[con.COUNTER_SELL_PUT_STRIKE].fillNa(0)
    let premium_gains = trans_df[con.PREMIUM_GAINS].fillNa(0)

    let execution_coverage = fwd_cov_amount.add(direct_call_delta_amount).add(direct_put_delta_amount)
    let all_amounts = execution_coverage.map((val, i) => {
        if (val === 0) {
            // Case when execution coverage is 0
            return fwd_counter_amount.values[i] + counter_buy_call_delta_amount.values[i] + counter_sell_call_delta_amount.values[i] + counter_buy_put_delta_amount.values[i] + counter_sell_put_delta_amount.values[i];
        } else {
            // Case when execution coverage isn't 0
            return execution_coverage.values[i];
        }
    });

    trans_df = trans_df.addColumn(con.ALL_AMOUNTS, all_amounts)

    
    let rate = fwd_cov_amount.mul(fwd_cov_rate.sub(spot))
    rate = rate.add(fwd_counter_amount.mul(fwd_counter_rate.sub(spot)).mul(-1))
    rate = rate.add(direct_call_delta_amount.mul(((direct_call_strike.sub(spot)).mul(-1)).maximum(0)).mul(sign))
    rate = rate.add(counter_buy_call_delta_amount.mul(((counter_buy_call_strike.sub(spot)).mul(-1)).maximum(0)).mul(sign))
    rate = rate.add(counter_sell_call_delta_amount.mul(((counter_sell_call_strike.sub(spot))).minimum(0)).mul(sign))
    rate = rate.add(direct_put_delta_amount.mul((direct_put_strike.sub(spot)).maximum(0)).mul(sign))
    rate = rate.add(counter_buy_put_delta_amount.mul((counter_buy_put_strike.sub(spot)).maximum(0)).mul(sign))
    rate = rate.add(counter_sell_put_delta_amount.mul((counter_sell_put_strike.sub(spot).mul(-1)).minimum(0)).mul(sign))
    rate = rate.add(premium_gains)
    rate = rate.div(all_amounts)
    rate = rate.add(spot)
    
    trans_df = trans_df.addColumn(con.RATE, rate)

    // Fills with zeros
    trans_df = trans_df.fillNa([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], {columns : 
        [ con.EXPOSURE_AMOUNT, con.FWD_COUNTER_COVERAGE_AMOUNT, con.FWD_COUNTER_RATE, con.DIRECT_CALL_AMOUNT,
            con.COUNTER_BUY_CALL_DELTA_AMOUNT, con.COUNTER_BUY_CALL_AMOUNT, con.COUNTER_BUY_CALL_STRIKE,
            con.COUNTER_SELL_CALL_DELTA_AMOUNT, con.COUNTER_SELL_CALL_AMOUNT, con.COUNTER_SELL_CALL_STRIKE,
            con.DIRECT_PUT_AMOUNT, con.COUNTER_BUY_PUT_DELTA_AMOUNT, con.COUNTER_BUY_PUT_AMOUNT,
            con.COUNTER_BUY_PUT_STRIKE,con.COUNTER_SELL_PUT_DELTA_AMOUNT, con.COUNTER_SELL_PUT_AMOUNT, con.COUNTER_SELL_PUT_STRIKE,
            con.OPTION_COVERAGE_AMOUNT, con.OPTION_COVERAGE_RATE, con.COVERAGE_AMOUNT, con.RATE]});

    
    // Adds Counter coverages amount
    let counter_cov_amount = trans_df[con.FWD_COUNTER_COVERAGE_AMOUNT].add(trans_df[con.COUNTER_BUY_CALL_DELTA_AMOUNT]).add(trans_df[con.COUNTER_BUY_PUT_DELTA_AMOUNT]).add(trans_df[con.COUNTER_SELL_CALL_DELTA_AMOUNT]).add(trans_df[con.COUNTER_SELL_PUT_DELTA_AMOUNT])
    trans_df = trans_df.addColumn(con.COUNTER_COVERAGE_AMOUNT, counter_cov_amount)

    // Adds net Exposure
    let net_exposure_amount = trans_df[con.EXPOSURE_AMOUNT].sub(trans_df[con.COVERAGE_AMOUNT]).add(counter_cov_amount)
    trans_df = trans_df.addColumn(con.NET_EXPOSURE_AMOUNT, net_exposure_amount)

    // Sorts
    trans_df = sortByDate(trans_df, con.DATE);
    return(trans_df)

}

const removeExposures = (trans_df, accounts) =>
{
    accounts = new dfd.DataFrame(Object.values(accounts).map((row) => {return({account_type : row.account_type, amount : row.amount})}));

    // Sums total in accounts
    let total = accounts[con.AMOUNT].sum();

    // Removes chronologically
    let new_exposures = trans_df[con.EXPOSURE_AMOUNT].apply((d) => {
    
                        let to_remove = Math.min(d,total)
                        total -= to_remove
                    
                        return(d - to_remove)
                    
                        })
    
    // Assigns new values
    trans_df = setColumn(trans_df, con.EXPOSURE_AMOUNT, new_exposures)

    return(trans_df)

}


const attachDollarScenario = (current_date, spot, trans_df, scenarioGenerationFun) =>
{
    // NOTE: trans_df should be sorted by dates

    // Gets the days from current date
    let amount_days = trans_df[con.DATE].map((d) => dateFun.daysBetweenDates(current_date, d))


    // Gets the dolar price
    let dollar_values =  amount_days.map((i)=> scenarioGenerationFun(spot, i))
    
    // Adds column
    trans_df = trans_df.addColumn(con.VAR_RATE, dollar_values)

    // Adds max_var_rate column
    trans_df = trans_df.addColumn(con.MAX_VAR_RATE,dollar_values)
        
    
    return(trans_df)
}

const optionVARConsolidation = (trans_df) => {

    const minMax = (columns, row, col, m) => {
        const var_index = columns.indexOf(con.VAR_RATE)
        const col_index = columns.indexOf(col)
        return m === "min" ? Math.min(row[var_index], row[col_index]) : Math.max(row[var_index], row[col_index])
    }

    const execution = (columns, row, colStrike, colAmount, op_type) => {
        const var_index = columns.indexOf(con.VAR_RATE)
        const colS_index = columns.indexOf(colStrike)
        const colA_index = columns.indexOf(colAmount)
        if(op_type === con.CALL){
            return row[var_index] > row[colS_index] ? row[colA_index] : 0
        } else {
            return row[var_index] < row[colS_index] ? row[colA_index] : 0
        }
    }

    const executionDirectCallVar = trans_df.apply(row => execution(trans_df.columns, row, con.DIRECT_CALL_STRIKE, con.DIRECT_CALL_AMOUNT, con.CALL), { axis: 1 });
    const executionCounterBuyCallVar = trans_df.apply(row => execution(trans_df.columns, row, con.COUNTER_BUY_CALL_STRIKE, con.COUNTER_BUY_CALL_AMOUNT, con.CALL), { axis: 1 });
    const executionCounterSellCallVar = trans_df.apply(row => execution(trans_df.columns, row, con.COUNTER_SELL_CALL_STRIKE, con.COUNTER_SELL_CALL_AMOUNT, con.CALL), { axis: 1 });
    const executionDirectPutVar = trans_df.apply(row => execution(trans_df.columns, row, con.DIRECT_PUT_STRIKE, con.DIRECT_PUT_AMOUNT, con.PUT), { axis: 1 });
    const executionCounterBuyPutVar = trans_df.apply(row => execution(trans_df.columns, row, con.COUNTER_BUY_PUT_STRIKE, con.COUNTER_BUY_PUT_AMOUNT, con.PUT), { axis: 1 });
    const executionCounterSellPutVar = trans_df.apply(row => execution(trans_df.columns, row, con.COUNTER_SELL_PUT_STRIKE, con.COUNTER_SELL_PUT_AMOUNT, con.PUT), { axis: 1 });

    
    const directCallSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.DIRECT_CALL_STRIKE, "min"), { axis: 1 });
    const counterBuyCallSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.COUNTER_BUY_CALL_STRIKE, "min"), { axis: 1 });
    const counterSellCallSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.COUNTER_SELL_CALL_STRIKE, "min"), { axis: 1 });
    const directPutSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.DIRECT_PUT_STRIKE, "max"), { axis: 1 });
    const counterBuyPutSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.COUNTER_BUY_PUT_STRIKE, "max"), { axis: 1 });
    const counterSellPutSettlementRate = trans_df.apply(row => minMax(trans_df.columns, row, con.COUNTER_SELL_PUT_STRIKE, "max"), { axis: 1 });
    
    
    trans_df = trans_df.addColumn(con.EXECUTION_DIRECT_CALL_VAR, executionDirectCallVar);
    trans_df = trans_df.addColumn(con.EXECUTION_COUNTER_BUY_CALL_VAR, executionCounterBuyCallVar);
    trans_df = trans_df.addColumn(con.EXECUTION_COUNTER_SELL_CALL_VAR, executionCounterSellCallVar);
    trans_df = trans_df.addColumn(con.EXECUTION_DIRECT_PUT_VAR, executionDirectPutVar);
    trans_df = trans_df.addColumn(con.EXECUTION_COUNTER_BUY_PUT_VAR, executionCounterBuyPutVar);
    trans_df = trans_df.addColumn(con.EXECUTION_COUNTER_SELL_PUT_VAR, executionCounterSellPutVar);

    
    trans_df = trans_df.addColumn(con.DIRECT_CALL_SETTLEMENT_RATE, directCallSettlementRate);
    trans_df = trans_df.addColumn(con.COUNTER_BUY_CALL_SETTLEMENT_RATE, counterBuyCallSettlementRate);
    trans_df = trans_df.addColumn(con.COUNTER_SELL_CALL_SETTLEMENT_RATE, counterSellCallSettlementRate);
    trans_df = trans_df.addColumn(con.DIRECT_PUT_SETTLEMENT_RATE, directPutSettlementRate);
    trans_df = trans_df.addColumn(con.COUNTER_BUY_PUT_SETTLEMENT_RATE, counterBuyPutSettlementRate);
    trans_df = trans_df.addColumn(con.COUNTER_SELL_PUT_SETTLEMENT_RATE, counterSellPutSettlementRate);


    trans_df = trans_df.fillNa([0,0,0,0,0,0,0,0,0,0,0,0], {columns : [ con.EXECUTION_DIRECT_CALL_VAR, con.EXECUTION_COUNTER_BUY_CALL_VAR, con.EXECUTION_COUNTER_SELL_CALL_VAR,
                                                                       con.EXECUTION_DIRECT_PUT_VAR, con.EXECUTION_COUNTER_BUY_PUT_VAR, con.EXECUTION_COUNTER_SELL_PUT_VAR,
                                                                       con.DIRECT_CALL_SETTLEMENT_RATE, con.COUNTER_BUY_CALL_SETTLEMENT_RATE, con.COUNTER_SELL_CALL_SETTLEMENT_RATE,
                                                                       con.DIRECT_PUT_SETTLEMENT_RATE, con.COUNTER_BUY_PUT_SETTLEMENT_RATE, con.COUNTER_SELL_PUT_SETTLEMENT_RATE]});

    return(trans_df)
}


const adjustPeriodicity = (trans_df, periodicity) =>
{
    if(periodicity === con.PERIODICITY_DAY)
        return(trans_df)

    // Replaces column
    let new_dates = trans_df[con.DATE].map((d) => dateFun.formatDate(dateFun.takeDateToEndOfPeriodicity(d, periodicity)))
    trans_df = setColumn(trans_df, con.DATE, new_dates)
    

    return(trans_df)

}


const groupByDate = (trans_df) =>
{    
    
    // Extracts original columns
    let cols = trans_df.columns

    // Columns that need to be weighted averaged with other columns (as dictionary)
    let cols_to_avg = {[con.VAR_RATE] : con.NET_EXPOSURE_AMOUNT,
                       [con.RATE] : con.COVERAGE_AMOUNT,            
                       [con.FWD_COVERAGE_RATE] : con.FWD_COVERAGE_AMOUNT,                       
                       [con.FWD_COUNTER_RATE] : con.FWD_COUNTER_COVERAGE_AMOUNT,                       
                       [con.OPTION_COVERAGE_RATE] : con.OPTION_COVERAGE_AMOUNT,
                       [con.DIRECT_CALL_SETTLEMENT_RATE] : con.DIRECT_CALL_AMOUNT,
                       [con.COUNTER_BUY_CALL_SETTLEMENT_RATE] : con.COUNTER_BUY_CALL_AMOUNT,
                       [con.COUNTER_SELL_CALL_SETTLEMENT_RATE] : con.COUNTER_SELL_CALL_AMOUNT,
                       [con.DIRECT_PUT_SETTLEMENT_RATE] : con.DIRECT_PUT_AMOUNT,
                       [con.COUNTER_BUY_PUT_SETTLEMENT_RATE] : con.COUNTER_BUY_PUT_AMOUNT,
                       [con.COUNTER_SELL_PUT_SETTLEMENT_RATE] : con.COUNTER_SELL_PUT_AMOUNT,      
                       [con.EFFECTIVE_RATE] : con.NET_EXPOSURE_AMOUNT,
                       [con.VAR_X_USD] : con.NET_EXPOSURE_AMOUNT,
                       [`${con.LOWER_BAND}_${con.VAR_X_USD}`] :con.NET_EXPOSURE_AMOUNT,
                       [`${con.MID_BAND}_${con.VAR_X_USD}`] :con.NET_EXPOSURE_AMOUNT,
                       [`${con.HIGHER_BAND}_${con.VAR_X_USD}`] :con.NET_EXPOSURE_AMOUNT};



    // Columns that will be maxed. 
    let cols_to_max = [con.MAX_VAR_RATE, `${con.LOWER_BAND}_${con.PERCENTAGES}`,`${con.MID_BAND}_${con.PERCENTAGES}`,`${con.HIGHER_BAND}_${con.PERCENTAGES}`]
    
    // Note: The rest of the columns will be summed over

    // -----------------------------
    // Extracts the totals for each columns that need to be weighted
    let cols_weight = Object.values(cols_to_avg)

    let cols_temp = cols.filter((col) => cols_weight.includes(col))
    cols_temp.push(con.DATE)
    let totals = trans_df.loc({columns : cols_temp}).groupby([con.DATE]).sum().setIndex({column:con.DATE})

    // Extracts the max for each column that needs to be maxed
    cols_temp = cols.filter((col) => cols_to_max.includes(col))
    cols_temp.push(con.DATE)
    let maxs = trans_df.loc({columns : cols_temp }).groupby([con.DATE]).max().setIndex({column:con.DATE});

    
    // Multiplies the columns that need to be averaged with weights
    // Iterates over dictionary
    Object.keys(cols_to_avg).forEach(col_avg => {
        

        // Extracts column for weight
        let col_weight = cols_to_avg[col_avg]
        if(cols.includes(col_avg))
        {
            let temp_series = trans_df[col_avg].mul(trans_df[col_weight]);
            trans_df = setColumn(trans_df, col_avg, temp_series)
        }
    })
    
    // Sums all columns
    trans_df = trans_df.groupby([con.DATE]).sum().setIndex({column:con.DATE})



    // Renames columns (removes the _sum from the name)
    trans_df = trans_df.rename( Object.assign({}, ...cols.map((col) => ({[`${col}_sum`]: col}))));
    
    // Divides the columns that need to be averaged with weights by their totals
    // Iterates over dictionary
    Object.keys(cols_to_avg).forEach(col_avg => {

        // Extracts column for weight
        let col_weight = cols_to_avg[col_avg]

        if(cols.includes(col_avg))
        {
            let temp_series = trans_df[col_avg].div(totals[`${col_weight}_sum`]);
            trans_df = setColumn(trans_df, col_avg, temp_series)
        }
    })


    // Adds the max columns (attaches _max at the end of the column)
    cols_to_max.filter((col) => cols.includes(col)).forEach(col => 
        trans_df = setColumn(trans_df, col, maxs[`${col}_max`]))

    
    // Special Columns
    if(cols.includes(con.COVERAGE_PERCENTAGE ))
    {
        let coverage_percentage = computeDirectCoverageAverage(trans_df)

        trans_df = setColumn(trans_df, con.COVERAGE_PERCENTAGE, coverage_percentage )
        trans_df = trans_df.fillNa([0], {columns: [con.COVERAGE_PERCENTAGE]})
    
   
    }
    if(cols.includes(con.COUNTER_COVERAGE_PERCENTAGE ))
    {
        // Counter
        let counter_coverage_percentage = computeCounterCoverageAverage(trans_df)

        trans_df = setColumn(trans_df, con.COUNTER_COVERAGE_PERCENTAGE, counter_coverage_percentage )
        trans_df = trans_df.fillNa([0], {columns: [con.COUNTER_COVERAGE_PERCENTAGE]}) 

    }
                      
    // Resets index  
    trans_df = trans_df.resetIndex()
    
    // Fills with zeros
    trans_df = trans_df.fillNa(Array(trans_df.columns.length).fill(0), {columns : trans_df.columns});

    return(trans_df)                        
}


const applyOverCoverageScheme = (trans_df) =>
{

    // NOTE: trans_df should be sorted by dates

    // Applies over coverage scheme

    // For over coverages tracking (single account)
    let over_coverage = 0
    let over_rate = 0

    // New coverages and dates
    let new_coverages = []
    let new_rates = []

    trans_df.index.forEach((ind) => {

        // Extratcs the row
        let row = trans_df.loc({rows: [ind]})

        // Extracts the necessary values
        // TODO
        // Potential Bug, Accesing data through $
        let amount_to_cover = row[con.NET_EXPOSURE_AMOUNT]["$data"][0]
        let coverage = row[con.COVERAGE_AMOUNT]["$data"][0]
        let rate = row[con.RATE]["$data"][0]

        
        // New coverage and rate
        let new_coverage = 0
        let new_rate = 0

        // First uses the over coverage
        new_coverage = Math.min(amount_to_cover, over_coverage)
        new_rate = amount_to_cover > 0 ? over_rate : 0
        
        // Updates the over coverage and amount to cover
        amount_to_cover -= new_coverage
        over_coverage -= new_coverage

        // Adds current coverage to over coverage
        over_rate = coverage > 0 ? (over_rate*over_coverage + rate*coverage)/(over_coverage+coverage) : over_rate
        over_coverage += coverage

        // Applies if there is still amount to cover
        let remaining = Math.min(amount_to_cover, over_coverage)
        new_coverage += remaining
        new_rate = remaining > 0 ? over_rate : new_rate

        //Adjusts over rate and coverage
        over_coverage -= remaining
        over_rate = over_coverage === 0 ? 0 : over_rate
        
        // Appends new values
        new_coverages.push(new_coverage)
        new_rates.push(new_rate)
    })

    // Adjusts the final period if over coverage is remaining
    if(over_coverage > 0)
    {
        let i = trans_df.index[trans_df.index.length-1]    
        new_rates[i] = (over_rate*over_coverage + new_rates[i]*new_coverages[i])/(over_coverage+new_coverages[i])
        new_coverages[i] += over_coverage
    }

    // Assings the columns
    trans_df = setColumn(trans_df, con.COVERAGE_AMOUNT, new_coverages)
    trans_df = setColumn(trans_df, con.RATE, new_rates)

   
    return(trans_df)

}

// LEGACY
// const attachRealPosition = (trans_df) =>
// {    

//     let exp = trans_df[con.EXPOSURE_AMOUNT]
//     let cov = trans_df[con.COVERAGE_AMOUNT]

//     // Sets the exposure amount unless its 0, in that case it will be de coverage
//     let real_position = exp
//     real_position = real_position.add(cov.mul(exp.eq(0)))

//     trans_df = trans_df.addColumn(con.REAL_POSITION, real_position)

//     return(trans_df)

// }



const addOpportunisticVar = (trans_df, spot, client_type, passthrough) =>
{
    
    let exp = trans_df[con.EXPOSURE_AMOUNT]
    let var_rate = trans_df[con.VAR_RATE]
    let max_var_rate = trans_df[con.MAX_VAR_RATE]
    let fwd_cov_amount = trans_df[con.FWD_COVERAGE_AMOUNT]
    let fwd_cov_rate = trans_df[con.FWD_COVERAGE_RATE]
    let counter_fwd_amount = trans_df[con.FWD_COUNTER_COVERAGE_AMOUNT]
    let counter_fwd_rate = trans_df[con.FWD_COUNTER_RATE]
    let call_cov_amount = trans_df[con.EXECUTION_DIRECT_CALL_VAR]
    let put_cov_amount = trans_df[con.EXECUTION_DIRECT_PUT_VAR]
    let call_cov_rate = trans_df[con.DIRECT_CALL_SETTLEMENT_RATE]
    let put_cov_rate = trans_df[con.DIRECT_PUT_SETTLEMENT_RATE]
    let buy_call_counter_amount = trans_df[con.EXECUTION_COUNTER_BUY_CALL_VAR]
    let buy_put_counter_amount = trans_df[con.EXECUTION_COUNTER_BUY_PUT_VAR]
    let buy_call_counter_rate = trans_df[con.COUNTER_BUY_CALL_SETTLEMENT_RATE]
    let buy_put_counter_rate = trans_df[con.COUNTER_BUY_PUT_SETTLEMENT_RATE]
    let sell_call_counter_amount = trans_df[con.EXECUTION_COUNTER_SELL_CALL_VAR]
    let sell_put_counter_amount = trans_df[con.EXECUTION_COUNTER_SELL_PUT_VAR]
    let sell_call_counter_rate = trans_df[con.COUNTER_SELL_CALL_SETTLEMENT_RATE]
    let sell_put_counter_rate = trans_df[con.COUNTER_SELL_PUT_SETTLEMENT_RATE]
    let counter_coverage_amount = trans_df[con.COUNTER_COVERAGE_AMOUNT]
    let coverage_amount = trans_df[con.COVERAGE_AMOUNT]
    let gl_premiums = trans_df[con.PREMIUM_GAINS]

    let counter_buy_call_total_amount = trans_df[con.COUNTER_BUY_CALL_AMOUNT]
    let counter_sell_call_total_amount = trans_df[con.COUNTER_SELL_CALL_AMOUNT]
    let counter_buy_put_total_amount = trans_df[con.COUNTER_BUY_PUT_AMOUNT]
    let counter_sell_put_total_amount = trans_df[con.COUNTER_SELL_PUT_AMOUNT]



    // Add delta direct delta
    let op_cov_amount = trans_df[con.OPTION_COVERAGE_AMOUNT]
    let direct_call_amount = trans_df[con.DIRECT_CALL_AMOUNT]
    let direct_put_amount = trans_df[con.DIRECT_PUT_AMOUNT]
    let total_direct_amount = direct_call_amount.add(direct_put_amount)
    let direct_delta = op_cov_amount.div(total_direct_amount.add(epsylon))
    direct_delta = direct_delta.mul(total_direct_amount.gt(0))
    trans_df = trans_df.addColumn(con.DIRECT_DELTA, direct_delta)
    
    // Add delta counter delta
    let op_counter_cov_amount = trans_df[con.COUNTER_BUY_CALL_DELTA_AMOUNT].add(trans_df[con.COUNTER_SELL_CALL_DELTA_AMOUNT])
    op_counter_cov_amount = op_counter_cov_amount.add(trans_df[con.COUNTER_BUY_PUT_DELTA_AMOUNT]).add(trans_df[con.COUNTER_SELL_PUT_DELTA_AMOUNT])
    let counter_call_amount = trans_df[con.COUNTER_BUY_CALL_AMOUNT].add(trans_df[con.COUNTER_SELL_CALL_AMOUNT])
    let counter_put_amount = trans_df[con.COUNTER_BUY_PUT_AMOUNT].add(trans_df[con.COUNTER_SELL_PUT_AMOUNT])
    let total_counter_amount = counter_call_amount.add(counter_put_amount)
    let counter_delta = op_counter_cov_amount.div(total_counter_amount.add(epsylon))
    counter_delta = counter_delta.mul(total_counter_amount.gt(0))
    trans_df = trans_df.addColumn(con.COUNTER_DELTA, counter_delta)

    // Set Max Var where exposure is 0
    let var_rate_condition = exp.gt(0).or(counter_coverage_amount.gt(0)).or(coverage_amount.gt(0));
    let max_var_rate_condition = exp.eq(0).and(counter_coverage_amount.eq(0)).and(coverage_amount.eq(0));

    var_rate = var_rate.mul(var_rate_condition).add(max_var_rate.mul(max_var_rate_condition))
    trans_df = setColumn(trans_df, con.VAR_RATE, var_rate)

    // Coverages Percentages
    // --------------------

    let coverage_percentage = computeDirectCoverageAverage(trans_df)

    trans_df = trans_df.addColumn(con.COVERAGE_PERCENTAGE, coverage_percentage)
    trans_df = trans_df.fillNa([0], {columns: [con.COVERAGE_PERCENTAGE]})

    // // Counter LEGACY
    // let counter_coverage_percentage = computeCounterCoverageAverage(trans_df)
    // trans_df = trans_df.addColumn(con.COUNTER_COVERAGE_PERCENTAGE, counter_coverage_percentage )
    // trans_df = trans_df.fillNa([0], {columns: [con.COUNTER_COVERAGE_PERCENTAGE]})
    
    // Computes sign
    let sign =  client_type === con.IMPORTER ? 1 : -1

    // // LEGACY
    // let counter_var_x_usd = counter_rate.sub(var_rate).mul(counter_coverage_percentage).mul(sign)
    // trans_df = trans_df.addColumn(con.COUNTER_VAR_X_USD, counter_var_x_usd )

    // Var by USD 
    // Benefit for passtrough is only for importer client 
    let benefit = 0;
    if(client_type === con.IMPORTER)   
        {
            benefit = var_rate.sub(spot).mul(passthrough)
            benefit = benefit.mul(benefit.gt(0)) // Only allows positive benefit
        } 

    // Adds effective rate
    let execution_coverage = fwd_cov_amount.add(call_cov_amount).add(put_cov_amount)
    const all_amounts = execution_coverage.map((val, i) => {
        if (val === 0) {
            // Case when execution coverage is 0
            return counter_fwd_amount.values[i] + counter_buy_call_total_amount.values[i] + counter_sell_call_total_amount.values[i] + counter_buy_put_total_amount.values[i] + counter_sell_put_total_amount.values[i];
        } else {
            // Case when execution coverage isn't 0
            return fwd_cov_amount.values[i] + call_cov_amount.values[i] + put_cov_amount.values[i] + counter_fwd_amount.values[i] + buy_call_counter_amount.values[i] + sell_call_counter_amount.values[i] + buy_put_counter_amount.values[i] + sell_put_counter_amount.values[i];
        }
    });
    

    let effective_rate = exp.mul(var_rate)
    effective_rate = effective_rate.sub(fwd_cov_amount.mul(var_rate.sub(fwd_cov_rate)).mul(sign))
    effective_rate = effective_rate.sub(call_cov_amount.mul(var_rate.sub(call_cov_rate)))
    effective_rate = effective_rate.sub(put_cov_amount.mul(put_cov_rate.sub(var_rate)))
    effective_rate = effective_rate.sub(counter_fwd_amount.mul(counter_fwd_rate.sub(var_rate)).mul(sign))
    effective_rate = effective_rate.sub(buy_call_counter_amount.mul(var_rate.sub(buy_call_counter_rate)))
    effective_rate = effective_rate.sub(sell_call_counter_amount.mul(sell_call_counter_rate.sub(var_rate)))
    effective_rate = effective_rate.sub(buy_put_counter_amount.mul(buy_put_counter_rate.sub(var_rate)))
    effective_rate = effective_rate.sub(sell_put_counter_amount.mul(var_rate.sub(sell_put_counter_rate)))
    effective_rate = effective_rate.add(gl_premiums)
    effective_rate = effective_rate.sub(benefit)
    let effective_rate_cov = effective_rate.div(all_amounts.add(epsylon))
    effective_rate = effective_rate.div(exp.add(epsylon))

    
    let var_x_usd = effective_rate.sub(spot)
    var_x_usd = var_x_usd.mul(exp.gt(0))
    var_x_usd = var_x_usd.add(effective_rate_cov.mul(exp.eq(0)))

    trans_df = trans_df.addColumn(con.VAR_X_USD,var_x_usd)
    trans_df = trans_df.fillNa([0], {columns: [con.VAR_X_USD]})

    effective_rate = effective_rate.mul(exp.gt(0)).add(var_x_usd.add(var_rate).mul(exp.eq(0)))
    trans_df = trans_df.addColumn(con.EFFECTIVE_RATE, effective_rate)
    trans_df = trans_df.fillNa([0], {columns: [con.EFFECTIVE_RATE]})

    // Opportunistic Var
    let opportunistic_var = var_x_usd.mul(exp)
    opportunistic_var = opportunistic_var.add(var_x_usd.mul(all_amounts).mul(exp.eq(0)))
    trans_df = trans_df.addColumn(con.VAR,  opportunistic_var)
    trans_df = trans_df.fillNa([0], {columns: [con.VAR]})   


    return(trans_df)
}


const addVarByBands = (current_date, trans_df, spot, client_type, average_for_percentage, monthly_bands, bands_with) =>
{

    // Term in months
    let num_periods = trans_df.shape[0];

    // Gets month periods
    let month_periods = trans_df[con.DATE].apply((d) => dateFun.monthsBetweenDates(current_date, d)).values

    // Creates the percentage vector
    let terms = [...Array(num_periods).keys()].map(val => val === 0 ? 1 : val) // Replaces 0 with 1 to avoid Nan

    // Extracts the transactions    
    let all_amounts = trans_df[con.ALL_AMOUNTS].tensor
    let exposures = trans_df[con.EXPOSURE_AMOUNT].tensor
    let counter = trans_df[con.COUNTER_COVERAGE_AMOUNT].tensor
    let effective_rate = trans_df[con.EFFECTIVE_RATE].tensor
    let coverages = trans_df[con.COVERAGE_AMOUNT].tensor
    let coverage_percentage = trans_df[con.COVERAGE_PERCENTAGE].tensor
    let total_expo = exposures.add(counter)
    // Sets Max Coverage to 1
    coverage_percentage = coverage_percentage.mul(coverage_percentage.less(1)).add(coverage_percentage.greaterEqual(1).mul(1))
    
    let var_rate = trans_df[con.VAR_RATE].tensor
    let max_var_rate = trans_df[con.MAX_VAR_RATE].tensor

    

    // Iterates over each Band
    con.BANDS.forEach((band_type) =>{

        let sign =  client_type === con.IMPORTER ? 1 : -1
        
        // Extracts the band function 
        let bands_fun = getBandsConstructionFunction(monthly_bands, bands_with, band_type);

        let band_values = tf.tensor(month_periods.map((i) => bands_fun(i)))        
        let percentages = band_values.sub(coverage_percentage).mul(average_for_percentage).div(terms)

        
        // Sets negatives to zero
        percentages = tf.maximum(percentages, 0)
       

        // Easy way to set first to zero
        percentages = percentages.dataSync()
        percentages[0] = 0
        percentages = tf.tensor(percentages)


        // Creates Matrix
        // Matrix where if column >= row the the value is zero
        // NOTE: There is probably a better way to do this using Danfo.js or TensorFlow.js but for the life of me
        // I could not find it. I looked for a way to update values based on slicing in Danfos, but the documentation is still pretty
        // raw and not a lot of resouces online for it

        let percentage_array = percentages.dataSync();
        let matrix = tf.stack([...Array(num_periods).keys()].map((i) => percentage_array.map((_, j) => j >= i ? 0 : percentage_array[i])))        
        // Multiplies by exposures plus counter coverages
        matrix = matrix.mul(tf.reshape(total_expo,[num_periods,1]))
        
        // Sums
        let sums = matrix.sum(1)
   

        // Extracts the effective rate by band
        // Computes totals 
        let percentage_amount = matrix.mul(max_var_rate).sum(1)
        percentage_amount = percentage_amount.sub(sums.mul(var_rate)).mul(sign).mul(-1)     
        

        // Constructs rate 
        // let efective_band_rate = covered_amount.add(percentage_amount).add(oportunistic_amount).div(exposures.add(epsylon))
        let efective_band_rate = effective_rate.mul(exposures)
        efective_band_rate = efective_band_rate.add(percentage_amount);
        let effective_rate_cov = efective_band_rate.div(all_amounts.add(epsylon))
        efective_band_rate = efective_band_rate.div(exposures.add(epsylon))

        // Var by USD
        let var_x_usd =  efective_band_rate.sub(spot).mul(exposures.greater(0))
        var_x_usd = var_x_usd.add(effective_rate_cov.mul(exposures.equal(0)))

    
        // If net_exposure_amount is <= 0, will assign the efective rate
        efective_band_rate = efective_band_rate.mul(exposures.greater(0)).add(var_x_usd.add(spot).mul(exposures.equal(0)))

        // Band Var
        let band_var =  var_x_usd.mul(exposures).mul(exposures.greater(0))
        band_var = band_var.add(var_x_usd.mul(all_amounts).mul(exposures.equal(0)))

        // Adds percentages
        trans_df = trans_df.addColumn(`${band_type}_${con.PERCENTAGES}`, new dfd.Series(band_values))

        // Adds the percentage to reach
        // let percentage_to_reach = tf.maximum(0, band_values.mul(net_exposure_amount).sub(coverages))
        // trans_df = trans_df.addColumn(`${band_type}_${con.PERCENTAGE_TO_REACH}`, new dfd.Series(percentage_to_reach))


        // Adds amount to reach the band
        let amount_to_reach = tf.maximum(0, band_values.mul(exposures).add(counter).sub(coverages))
        trans_df = trans_df.addColumn(`${band_type}_${con.AMOUNT_TO_REACH}`, new dfd.Series(amount_to_reach))

        // Adds the Band Var
        trans_df = trans_df.addColumn(`${band_type}_${con.VAR}`, new dfd.Series(band_var))

        // Adds the Var by USD
        trans_df = trans_df.addColumn(`${band_type}_${con.VAR_X_USD}`, new dfd.Series(var_x_usd))


    })

    return(trans_df)

}




const attachPositionInBands = (trans_df) =>
{

    
    // Calculates the band width
    let band_width = trans_df[`${con.HIGHER_BAND}_${con.PERCENTAGES}`].sub(trans_df[`${con.LOWER_BAND}_${con.PERCENTAGES}`])
    let difference = trans_df[con.COVERAGE_PERCENTAGE].sub(trans_df[`${con.LOWER_BAND}_${con.PERCENTAGES}`]).div(band_width)

    // Adjusts Possible Values
    difference = difference.mul(con.POSITION_IN_BANDS_ARRAY.length - 2)

    // Adjusts the extremes
    difference = difference.map((v) => v < 0 ? -1 : v)
    difference = difference.map((v) => v > con.POSITION_IN_BANDS_ARRAY.length - 2 ? con.POSITION_IN_BANDS_ARRAY.length - 2 : v)

    
    // Construct index
    let ind = difference.map((v) => Math.floor(v) + 1)

    // Construct Positions
    let pos = ind.map((i) => con.POSITION_IN_BANDS_ARRAY[i])

    // Adds column
    trans_df = trans_df.addColumn(con.POSITION_IN_BANDS, pos)

    return(trans_df)

    
}


// Create Summary
const createSummary = async (trans_df, modelParameters) =>{

    // Unlinks
    trans_df = trans_df.copy()

    // Drops String columns
    trans_df = trans_df.drop({ columns: [con.POSITION_IN_BANDS,con.PERIOD]})

    // Sets constant date
    trans_df = setColumn(trans_df, con.DATE, Array(trans_df.shape[0]).fill(con.TOTAL)) 

    // Applies group by
    trans_df = groupByDate(trans_df)
    let coverage_percentage = computeDirectCoverageAverage(trans_df)
    trans_df = setColumn(trans_df, con.COVERAGE_PERCENTAGE, coverage_percentage)
        
    // Adjust for bands        
    let [lBand, mlBand, mBand, mhBand, hBand] = getSummaryBands(modelParameters[con.MID_BAND_VALUES], modelParameters[con.BAND_WIDTH])

    trans_df = setColumn(trans_df, `${con.HIGHER_BAND}_${con.PERCENTAGES}`, [hBand])
    trans_df = setColumn(trans_df, `${con.MID_LOW_BAND}_${con.PERCENTAGES}`, [mlBand])
    trans_df = setColumn(trans_df, `${con.MID_BAND}_${con.PERCENTAGES}`, [mBand])
    trans_df = setColumn(trans_df, `${con.MID_HIGH_BAND}_${con.PERCENTAGES}`, [mhBand])
    trans_df = setColumn(trans_df, `${con.LOWER_BAND}_${con.PERCENTAGES}`, [lBand])
    
    // Computes String columns
    trans_df = attachPositionInBands(trans_df) 
    trans_df = trans_df.addColumn(con.PERIOD, [con.TOTAL])



    // Converts to JSON
    let resp = dfd.toJSON(trans_df, { download: false })[0]

    return(resp)

}



export const nonZeroTranactionFunction = (ob) => {

    return(ob[con.NET_EXPOSURE_AMOUNT] !== 0 || ob[con.COVERAGE_AMOUNT] !== 0)
}


export const buildDefaultRow = (columnArray) =>
{
  
    let response = Object.fromEntries(columnArray.map((col) => {
      
        var val = getDefaultValue(col.type)
        if(con.DEFAULT_VALUE in col)
          val = (typeof col[con.DEFAULT_VALUE] === 'function') ? col[con.DEFAULT_VALUE]() : col[con.DEFAULT_VALUE]
    
        return([col[con.ID], val])        
      }))


    return(response)
}

export const getAverageExpiryDay = (current_date, transactions) =>
{
    let amount_days = Object.values(transactions).map((d) => dateFun.daysBetweenDates(current_date, d[con.EXPIRATION_DATE]))
    amount_days = amount_days.filter( d => d >= 0)

    return(amount_days.length === 0 ? 0 : average(amount_days))
}



// Columns
export const allTransactionsCol = 
{ [con.PERIOD] : { [con.ID] : con.PERIOD, [con.NAME] : 'PERIODO', [con.TYPE] : con.TEXT},
  [con.USERNAME] : { [con.ID] : con.USERNAME, [con.NAME] : 'NOMBRE USUARIO', [con.TYPE] : con.TEXT},
  [con.ADVISOR] : { [con.ID] : con.ADVISOR, [con.NAME] : 'NOMBRE ASESOR', [con.TYPE] : con.TEXT},  
  [con.LINE] : { [con.ID] : con.LINE, [con.NAME] : 'LINEA', [con.TYPE] : con.TEXT},
  [con.DATE] : { [con.ID] : con.DATE, [con.NAME] : 'FECHA', [con.TYPE] : con.DATE},
  [con.EXPOSURE_AMOUNT] : { [con.ID] : con.EXPOSURE_AMOUNT, [con.NAME] : 'EXP. (MILES)', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.DENOMINATOR] : con.THOUSAND, allowDecimals : true},
  [con.COVERAGE_AMOUNT] : { [con.ID] : con.COVERAGE_AMOUNT, [con.NAME] : 'COB. (MILES)', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.DENOMINATOR] : con.THOUSAND, allowDecimals : true},                      
  [con.RATE] : { [con.ID] : con.RATE, [con.NAME] : 'TASA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [con.FWD_COVERAGE_AMOUNT] : { [con.ID] : con.FWD_COVERAGE_AMOUNT, [con.NAME] : 'COB. FWD', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.DENOMINATOR] : con.THOUSAND, allowDecimals : true},
  [con.FWD_COVERAGE_RATE] : { [con.ID] : con.FWD_COVERAGE_RATE, [con.NAME] : 'TASA FWD', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [con.DIRECT_DELTA] : { [con.ID] : con.DIRECT_DELTA, [con.NAME] : 'COB. DELTA', [con.TYPE] :con.PERCENTAGE},
  [con.COUNTER_DELTA] : { [con.ID] : con.COUNTER_DELTA, [con.NAME] : 'DELTA CONT.', [con.TYPE] :con.PERCENTAGE},
  [con.OPTION_COVERAGE_AMOUNT] : { [con.ID] : con.OPTION_COVERAGE_AMOUNT, [con.NAME] : 'COB. OPC.', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.DENOMINATOR] : con.THOUSAND, allowDecimals : true},
  [con.OPTION_COVERAGE_RATE] : { [con.ID] : con.OPTION_COVERAGE_RATE, [con.NAME] : 'TASA OPC.', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [con.COUNTER_COVERAGE_AMOUNT] : { [con.ID] : con.COUNTER_COVERAGE_AMOUNT, [con.NAME] : 'COB. CONTRARIAS', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
  [con.NET_EXPOSURE_AMOUNT] : { [con.ID] : con.NET_EXPOSURE_AMOUNT, [con.NAME] : 'EXP. NETA  (MILES)', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.DENOMINATOR] : con.THOUSAND, allowDecimals : true}, 
  [con.COVERAGE_PERCENTAGE] : {[con.ID] : con.COVERAGE_PERCENTAGE, [con.NAME] : '% COB.', [con.TYPE] : con.PERCENTAGE} ,
  [con.VAR_RATE]: { [con.ID] : con.VAR_RATE, [con.NAME] : 'TASA VAR', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP },
  [con.EFFECTIVE_RATE] : { [con.ID] : con.EFFECTIVE_RATE,[con.NAME] : 'TASA EFECTIVA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [con.VAR_X_USD] : { [con.ID] : con.VAR_X_USD, [con.NAME] : 'VAR POR USD', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
//   [con.COUNTER_VAR_X_USD] : { [con.ID] : con.COUNTER_VAR_X_USD, [con.NAME] : 'VAR POR USD (CONTRA)', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},  //LEGACY
  [con.VAR] : { [con.ID] : con.VAR, [con.NAME] : 'VAR OPORTU. (MILLONES)', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true},
  [`${con.LOWER_BAND}_${con.PERCENTAGES}`] : { [con.ID] : `${con.LOWER_BAND}_${con.PERCENTAGES}`, [con.NAME] : '% OBJ. BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_LOW_BAND}_${con.PERCENTAGES}`] : { [con.ID] : `${con.MID_LOW_BAND}_${con.PERCENTAGES}`, [con.NAME] : '% OBJ. BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_BAND}_${con.PERCENTAGES}`] : { [con.ID] : `${con.MID_BAND}_${con.PERCENTAGES}`, [con.NAME] : '% OBJ. BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_HIGH_BAND}_${con.PERCENTAGES}`] : { [con.ID] : `${con.MID_HIGH_BAND}_${con.PERCENTAGES}`, [con.NAME] : '% OBJ. BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.HIGHER_BAND}_${con.PERCENTAGES}`] : { [con.ID] : `${con.HIGHER_BAND}_${con.PERCENTAGES}`, [con.NAME] : '% OBJ. BANDA', [con.TYPE] : con.PERCENTAGE },

  [`${con.LOWER_BAND}_${con.PERCENTAGE_TO_REACH}`] : { [con.ID] : `${con.LOWER_BAND}_${con.PERCENTAGE_TO_REACH}`, [con.NAME] : '% PARA LLEGAR A BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_LOW_BAND}_${con.PERCENTAGE_TO_REACH}`] : { [con.ID] : `${con.MID_LOW_BAND}_${con.PERCENTAGE_TO_REACH}`, [con.NAME] : '% PARA LLEGAR A BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_BAND}_${con.PERCENTAGE_TO_REACH}`] : { [con.ID] : `${con.MID_BAND}_${con.PERCENTAGE_TO_REACH}`, [con.NAME] : '% PARA LLEGAR A BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.MID_HIGH_BAND}_${con.PERCENTAGE_TO_REACH}`] : { [con.ID] : `${con.MID_HIGH_BAND}_${con.PERCENTAGE_TO_REACH}`, [con.NAME] : '% PARA LLEGAR A BANDA', [con.TYPE] : con.PERCENTAGE },
  [`${con.HIGHER_BAND}_${con.PERCENTAGE_TO_REACH}`] : { [con.ID] : `${con.HIGHER_BAND}_${con.PERCENTAGE_TO_REACH}`, [con.NAME] : '% PARA LLEGAR A BANDA', [con.TYPE] : con.PERCENTAGE },

  [`${con.LOWER_BAND}_${con.AMOUNT_TO_REACH}`] : { [con.ID] : `${con.LOWER_BAND}_${con.AMOUNT_TO_REACH}`, [con.NAME] : 'MONTO PARA LLEGAR A BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
  [`${con.MID_LOW_BAND}_${con.AMOUNT_TO_REACH}`] : { [con.ID] : `${con.MID_LOW_BAND}_${con.AMOUNT_TO_REACH}`, [con.NAME] : 'MONTO PARA LLEGAR A BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
  [`${con.MID_BAND}_${con.AMOUNT_TO_REACH}`] : { [con.ID] : `${con.MID_BAND}_${con.AMOUNT_TO_REACH}`, [con.NAME] : 'MONTO PARA LLEGAR A BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
  [`${con.MID_HIGH_BAND}_${con.AMOUNT_TO_REACH}`] : { [con.ID] : `${con.MID_HIGH_BAND}_${con.AMOUNT_TO_REACH}`, [con.NAME] : 'MONTO PARA LLEGAR A BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
  [`${con.HIGHER_BAND}_${con.AMOUNT_TO_REACH}`] : { [con.ID] : `${con.HIGHER_BAND}_${con.AMOUNT_TO_REACH}`, [con.NAME] : 'MONTO PARA LLEGAR A BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_USD},
        
  [con.POSITION_IN_BANDS] : { [con.ID] : con.POSITION_IN_BANDS, [con.NAME] : 'Posición Banda', [con.TYPE] : con.TEXT, [con.DEFAULT_VALUE]: con.POSITION_IN_BANDS_ARRAY[0], [con.COMPRESS] : (val) => val.replace("Banda", "B.")},            
         
  [`${con.LOWER_BAND}_${con.VAR_X_USD}`] : { [con.ID] : `${con.LOWER_BAND}_${con.VAR_X_USD}`, [con.NAME] : 'VAR POR USD BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [`${con.MID_LOW_BAND}_${con.VAR_X_USD}`] : { [con.ID] : `${con.MID_LOW_BAND}_${con.VAR_X_USD}`, [con.NAME] : 'VAR POR USD BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [`${con.MID_BAND}_${con.VAR_X_USD}`] : { [con.ID] : `${con.MID_BAND}_${con.VAR_X_USD}`, [con.NAME] : 'VAR POR USD BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [`${con.MID_HIGH_BAND}_${con.VAR_X_USD}`] : { [con.ID] : `${con.MID_HIGH_BAND}_${con.VAR_X_USD}`, [con.NAME] : 'VAR POR USD BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
  [`${con.HIGHER_BAND}_${con.VAR_X_USD}`] : { [con.ID] : `${con.HIGHER_BAND}_${con.VAR_X_USD}`, [con.NAME] : 'VAR POR USD BANDA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP},
                      
  [`${con.LOWER_BAND}_${con.VAR}`] : { [con.ID] : `${con.LOWER_BAND}_${con.VAR}`, [con.NAME] : 'VAR BANDA (MILLONES)', [con.TYPE] : con.MONEY,  [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true },
  [`${con.MID_LOW_BAND}_${con.VAR}`] : { [con.ID] : `${con.MID_LOW_BAND}_${con.VAR}`, [con.NAME] : 'VAR BANDA (MILLONES)', [con.TYPE] : con.MONEY,  [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true },
  [`${con.MID_BAND}_${con.VAR}`] : { [con.ID] : `${con.MID_BAND}_${con.VAR}`, [con.NAME] : 'VAR BANDA (MILLONES)', [con.TYPE] : con.MONEY,  [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true },
  [`${con.MID_HIGH_BAND}_${con.VAR}`] : { [con.ID] : `${con.MID_HIGH_BAND}_${con.VAR}`, [con.NAME] : 'VAR BANDA (MILLONES)', [con.TYPE] : con.MONEY,  [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true },
  [`${con.HIGHER_BAND}_${con.VAR}`] : { [con.ID] : `${con.HIGHER_BAND}_${con.VAR}`, [con.NAME] : 'VAR BANDA (MILLONES)', [con.TYPE] : con.MONEY,  [con.CURRENCY] : con.MONEY_COP, [con.DENOMINATOR] : con.MILLION, allowDecimals : true },

  // Summary
  [con.EXPIRY_DAYS] : {[con.ID] : con.EXPIRY_DAYS, [con.NAME] : 'VENCIMIENTO PROMEDIO (DIAS)', [con.TYPE] : con.NUMERIC, [con.EDITABLE] : false},
  [con.LAST_EDITED] : { [con.ID] : con.LAST_EDITED, [con.NAME] : 'ÚLTIMA EDICIÓN', [con.TYPE] : con.DATE_TIME},
  [con.OBJECTIVE_BAND] : { [con.ID] : con.OBJECTIVE_BAND, [con.NAME] : 'BANDA OBJECTIVO', [con.TYPE] : con.TEXT },
  [con.OBJECTIVE_BAND_PERCENTAGE] : { [con.ID] : con.OBJECTIVE_BAND_PERCENTAGE, [con.NAME] : '% BANDA OBJECTIVO', [con.TYPE] : con.PERCENTAGE },


}


export const selectedTransactionCols = [{ [con.ID] : con.DATE},
                                        { [con.ID] : con.EXPOSURE_AMOUNT},
                                        { [con.ID] : con.COVERAGE_AMOUNT},                      
                                        { [con.ID] : con.RATE},
                                        { [con.ID] : con.FWD_COVERAGE_AMOUNT},
                                        { [con.ID] : con.FWD_COVERAGE_RATE},
                                        { [con.ID] : con.DIRECT_DELTA},
                                        { [con.ID] : con.OPTION_COVERAGE_AMOUNT},
                                        { [con.ID] : con.OPTION_COVERAGE_RATE},
                                        { [con.ID] : con.COUNTER_DELTA},
                                        { [con.ID] : con.COUNTER_COVERAGE_AMOUNT},
                                        { [con.ID] : con.NET_EXPOSURE_AMOUNT}, 
                                        { [con.ID] : con.COVERAGE_PERCENTAGE} ,
                                        { [con.ID] : con.VAR_RATE},
                                        { [con.ID] : con.EFFECTIVE_RATE},
                                        { [con.ID] : con.VAR_X_USD},
                                        { [con.ID] : con.VAR}]
                                        // { [con.ID] : con.COUNTER_VAR_X_USD}, //
                                        // { [con.ID] : `${con.LOWER_BAND}_${con.PERCENTAGES}`},
                                        // { [con.ID] : `${con.MID_LOW_BAND}_${con.PERCENTAGES}`},
                                        // { [con.ID] : `${con.MID_BAND}_${con.PERCENTAGES}`},
                                        // { [con.ID] : `${con.MID_HIGH_BAND}_${con.PERCENTAGES}`},
                                        // { [con.ID] : `${con.HIGHER_BAND}_${con.PERCENTAGES}`},

                                        // { [con.ID] : `${con.LOWER_BAND}_${con.AMOUNT_TO_REACH}`},
                                        // { [con.ID] : `${con.MID_LOW_BAND}_${con.AMOUNT_TO_REACH}`},
                                        // { [con.ID] : `${con.MID_BAND}_${con.AMOUNT_TO_REACH}`},
                                        // { [con.ID] : `${con.MID_HIGH_BAND}_${con.AMOUNT_TO_REACH}`},
                                        // { [con.ID] : `${con.HIGHER_BAND}_${con.AMOUNT_TO_REACH}`}, 
                                                                
                                        // { [con.ID] : `${con.LOWER_BAND}_${con.VAR}`},
                                        // { [con.ID] : `${con.MID_LOW_BAND}_${con.VAR}`},
                                        // { [con.ID] : `${con.MID_BAND}_${con.VAR}`},
                                        // { [con.ID] : `${con.MID_HIGH_BAND}_${con.VAR}`},
                                        // { [con.ID] : `${con.HIGHER_BAND}_${con.VAR}`}]


export const mobileSelectedTransactionCols =  [{ [con.ID] : con.DATE},
                                                { [con.ID] : con.EXPOSURE_AMOUNT},
                                                { [con.ID] : con.COVERAGE_AMOUNT},                      
                                                { [con.ID] : con.RATE},                             
                                                { [con.ID] : con.COVERAGE_PERCENTAGE} ,
                                                { [con.ID] : con.POSITION_IN_BANDS},

                                                { [con.ID] : `${con.LOWER_BAND}_${con.VAR_X_USD}`},
                                                { [con.ID] : `${con.MID_LOW_BAND}_${con.VAR_X_USD}`},
                                                { [con.ID] : `${con.MID_BAND}_${con.VAR_X_USD}`},
                                                { [con.ID] : `${con.MID_HIGH_BAND}_${con.VAR_X_USD}`},
                                                { [con.ID] : `${con.HIGHER_BAND}_${con.VAR_X_USD}`},
                                                                    
                                                { [con.ID] : `${con.LOWER_BAND}_${con.VAR}`},
                                                { [con.ID] : `${con.MID_LOW_BAND}_${con.VAR}`},
                                                { [con.ID] : `${con.MID_BAND}_${con.VAR}`},
                                                { [con.ID] : `${con.MID_HIGH_BAND}_${con.VAR}`},
                                                { [con.ID] : `${con.HIGHER_BAND}_${con.VAR}`},
                                                ]


// Exposure Columns
export const allExposureColumns = {
    [con.EXPIRATION_DATE] : {[con.ID] : con.EXPIRATION_DATE, [con.TYPE] :  con.DATE, [con.DEFAULT_VALUE] :  () => dateFun.formatDate(dateFun.getNextWorkingDay()),[con.NAME] : "Fecha"},
    [con.AMOUNT] : { [con.ID] : con.AMOUNT, [con.TYPE] :  con.MONEY, [con.NAME] : "MONTO", [con.CURRENCY] : con.MONEY_USD, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0 },
    [con.OPENING_TRM] : {[con.ID] : con.OPENING_TRM, [con.TYPE] :  con.MONEY, [con.NAME] : "TRM APERTURA", [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0},
    [con.COMMENT] : { [con.ID] : con.COMMENT, [con.TYPE] :  con.TEXT, [con.NAME] : "Comentario", [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.STATE] : { [con.ID] : con.STATE, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.EDITABLE] : false, [con.NAME] : "Estado", [con.DEFAULT_VALUE] : con.ACTIVE, [con.OPTIONS] : [con.ACTIVE, con.EXPIRED], [con.VALUE_TRANSLATOR] : translateParameter}
}

export const selectedExposureColumns = [ {[con.ID] : con.EXPIRATION_DATE},
                                        { [con.ID] : con.AMOUNT},
                                        { [con.ID] : con.OPENING_TRM},
                                        { [con.ID] : con.COMMENT},
                                        { [con.ID] : con.STATE}]

// Forward Coverage Columns
export const allForwardCoverageColumns = {
    [con.EXPIRATION_DATE] : { [con.ID] : con.EXPIRATION_DATE, [con.TYPE] :  con.DATE,  [con.DEFAULT_VALUE] :  () => dateFun.formatDate(dateFun.getNextWorkingDay()), [con.NAME] : "FECHA VENCIMIENTO"},
    [con.OPENING_DATE] : { [con.ID] : con.OPENING_DATE, [con.TYPE] :  con.DATE, [con.NAME] : "FECHA APERTURA",  [con.DEFAULT_VALUE] :  () => dateFun.isWorkDay(dateFun.getToday()) ? dateFun.formatDate(dateFun.getToday()) : dateFun.formatDate(dateFun.getNextWorkingDay()), [con.SHOULD_DISABLE_DATE] : (date)=> !dateFun.isWorkDay(date) || date > dateFun.getToday()},
    [con.AMOUNT] : {[con.ID] : con.AMOUNT, [con.TYPE] :  con.MONEY, [con.NAME] : "MONTO", [con.CURRENCY]: con.MONEY_USD, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0 },
    [con.RATE] : { [con.ID] : con.RATE,[con.TYPE] :  con.MONEY, [con.NAME] : "TASA FORWARD", [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true , [con.DEFAULT_VALUE] :  () => 0 },
    [con.OPENING_SPOT] : {[con.ID] : con.OPENING_SPOT, [con.TYPE] :  con.MONEY, [con.NAME] : "SPOT APERTURA", [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] : () => getApplicationParameter(con.SPOT) },
    [con.COVERAGE_TYPE] : { [con.ID] : con.COVERAGE_TYPE, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.EDITABLE] : true, [con.NAME] : "TIPO", [con.DEFAULT_VALUE] : con.BUY, [con.OPTIONS] : [con.BUY, con.SELL], [con.VALUE_TRANSLATOR] : translateParameter},
    [con.COMMENT] : { [con.ID] : con.COMMENT, [con.TYPE] :  con.TEXT, [con.NAME] : "Comentario", [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.COUNTERPARTY] : { [con.ID] : con.COUNTERPARTY, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.OPTIONS] : con.COUNTERPARTIES, [con.NAME] : "Contraparte", [con.VALUE_TRANSLATOR] : counterpartiesNames, [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.STATE] : { [con.ID] : con.STATE, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.EDITABLE] : false, [con.NAME] : "Estado", [con.DEFAULT_VALUE] : con.ACTIVE, [con.OPTIONS] : [con.ACTIVE, con.EXPIRED], [con.VALUE_TRANSLATOR] : translateParameter},

    // Forward Assessment
    [con.EXPIRY_DAYS] : {[con.ID] : con.EXPIRY_DAYS, [con.NAME] : 'DIAS PARA VENCIMIENTO', [con.TYPE] : con.NUMERIC, [con.EDITABLE] : false},
    [con.DEVALUATION_VALUE] : {[con.ID] : con.DEVALUATION_VALUE, [con.NAME] : 'CURVA DE DEV.', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2},
    [con.DISCOUNT_RATE] : {[con.ID] : con.DISCOUNT_RATE, [con.NAME] : 'TASA DESCUENTO', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2},
    [con.NEW_FORWARD_RATE] : {[con.ID] : con.NEW_FORWARD_RATE, [con.NAME] : 'TASA VALORACIÓN', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false, [con.ALLOW_DECIMALS] : true },
    [con.FORWARD_RATE_DIFFERENCE] : {[con.ID] : con.FORWARD_RATE_DIFFERENCE, [con.NAME] : 'DIF. FWD', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false, [con.ALLOW_DECIMALS] : true },
    [con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE] : {[con.ID] : con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE, [con.NAME] : 'DIF. VALOR PRESENTE', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false, [con.ALLOW_DECIMALS] : true },
    [con.ASSESSMENT] : {[con.ID] : con.ASSESSMENT, [con.NAME] : 'VALORACIÓN', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false},

}

export const selectedForwardCoverageColumns = [ { [con.ID] : con.EXPIRATION_DATE },
                                                { [con.ID] : con.OPENING_DATE },
                                                { [con.ID] : con.AMOUNT },
                                                { [con.ID] : con.RATE },
                                                { [con.ID] : con.OPENING_SPOT },
                                                { [con.ID] : con.COVERAGE_TYPE },
                                                { [con.ID] : con.COUNTERPARTY },
                                                { [con.ID] : con.COMMENT},
                                                { [con.ID] : con.STATE}]


// Spot Coverage Columns
export const allSpotCoverageColumns = {
    
    [con.OPENING_DATE] : { [con.ID] : con.OPENING_DATE, [con.NAME] : 'Fecha Compra', [con.TYPE] :  con.DATE, [con.DEFAULT_VALUE] :  () => dateFun.isWorkDay(dateFun.getToday()) ? dateFun.formatDate(dateFun.getToday()) : dateFun.formatDate(dateFun.getPreviousWorkingDay()), [con.SHOULD_DISABLE_DATE] : (date)=> !dateFun.isWorkDay(date) || date > dateFun.getToday()},
    [con.AMOUNT] : { [con.ID] : con.AMOUNT, [con.NAME] : 'Monto', [con.TYPE] :  con.MONEY, [con.CURRENCY] : 'USD', [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0 },
    [con.RATE] : { [con.ID] : con.RATE, [con.NAME] : "Tasa Compra", [con.TYPE] :  con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] : () => getApplicationParameter(con.SPOT)},
    [con.COMMENT] : {[con.ID] : con.COMMENT,  [con.NAME] : 'Comentario', [con.TYPE] :  con.TEXT, [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.SPOT_COVERAGE_TYPE] : { [con.ID] : con.SPOT_COVERAGE_TYPE, [con.NAME] : 'Tipo', [con.TYPE] :  con.MULTIPLE_SELECTION, [con.DEFAULT_VALUE] : con.CASH_REGISTER, [con.OPTIONS] : [con.CASH_REGISTER], [con.EDITABLE] : false, [con.VALUE_TRANSLATOR] : translateParameter }
}      

export const selectedSpotCoverageColumns = [    { [con.ID] : con.OPENING_DATE },
                                                { [con.ID] : con.AMOUNT },
                                                { [con.ID] : con.RATE },
                                                { [con.ID] : con.COMMENT},
                                                { [con.ID] : con.SPOT_COVERAGE_TYPE}]

// Option Coverage Columns
export const allOptionCoverageColumns = {

    [con.EXPIRATION_DATE] : { [con.ID] : con.EXPIRATION_DATE, [con.TYPE] :  con.DATE,  [con.DEFAULT_VALUE] :  () => dateFun.formatDate(dateFun.getNextWorkingDay()), [con.NAME] : "FECHA VENCIMIENTO"},
    [con.OPENING_DATE] : { [con.ID] : con.OPENING_DATE, [con.NAME] : 'Fecha Compra', [con.TYPE] :  con.DATE, [con.DEFAULT_VALUE] :  () => dateFun.isWorkDay(dateFun.getToday()) ? dateFun.formatDate(dateFun.getToday()) : dateFun.formatDate(dateFun.getPreviousWorkingDay()), [con.SHOULD_DISABLE_DATE] : (date)=> !dateFun.isWorkDay(date) || date > dateFun.getToday()},
    [con.AMOUNT] : { [con.ID] : con.AMOUNT, [con.NAME] : 'Monto', [con.TYPE] :  con.MONEY, [con.CURRENCY] : 'USD', [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0  },
    [con.OPENING_SPOT] : {[con.ID] : con.OPENING_SPOT, [con.TYPE] :  con.MONEY, [con.NAME] : "SPOT", [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] : () => getApplicationParameter(con.SPOT) },
    [con.STRIKE] : { [con.ID] : con.STRIKE, [con.NAME] : "Strike", [con.TYPE] :  con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0 },
    [con.PREMIUM] : { [con.ID] : con.PREMIUM, [con.NAME] : "Prima", [con.TYPE] :  con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.ALLOW_DECIMALS] : true, [con.DEFAULT_VALUE] :  () => 0 },
    [con.OPTION_TYPE] : { [con.ID] : con.OPTION_TYPE, [con.NAME] : 'Call/Put', [con.TYPE] :  con.MULTIPLE_SELECTION, [con.DEFAULT_VALUE] : con.CALL, [con.OPTIONS] : [con.CALL, con.PUT], [con.EDITABLE] : true, [con.VALUE_TRANSLATOR] : translateParameter },
    [con.COVERAGE_TYPE] : { [con.ID] : con.COVERAGE_TYPE, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.EDITABLE] : true, [con.NAME] : "TIPO", [con.DEFAULT_VALUE] : con.BUY, [con.OPTIONS] : [con.BUY, con.SELL], [con.VALUE_TRANSLATOR] : translateParameter},
    [con.COUNTERPARTY] : { [con.ID] : con.COUNTERPARTY, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.OPTIONS] : con.COUNTERPARTIES, [con.NAME] : "Contraparte", [con.VALUE_TRANSLATOR] : counterpartiesNames, [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.COMMENT] : {[con.ID] : con.COMMENT,  [con.NAME] : 'Comentario', [con.TYPE] :  con.TEXT, [con.DEFAULT_VALUE] :  () => con.BLANK },
    [con.STATE] : { [con.ID] : con.STATE, [con.TYPE] :  con.MULTIPLE_SELECTION, [con.EDITABLE] : false, [con.NAME] : "Estado", [con.DEFAULT_VALUE] : con.ACTIVE, [con.OPTIONS] : [con.ACTIVE, con.EXPIRED], [con.VALUE_TRANSLATOR] : translateParameter},
    
    // Option Assessment
    [con.EXPIRY_DAYS] : { [con.ID] : [con.EXPIRY_DAYS], [con.NAME] : 'DIAS VTO', [con.TYPE] : con.NUMERIC, [con.EDITABLE] : false },
    [con.EXPIRE_YEARS] : { [con.ID] : [con.EXPIRE_YEARS], [con.NAME] : 'AÑOS PARA VENCIMEINTO' },
    [con.FOREIGN_RATE] : { [con.ID] : [con.FOREIGN_RATE], [con.NAME] : 'TASA EXT', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2},
    [con.DISCOUNT_RATE] : { [con.ID] : [con.DISCOUNT_RATE], [con.NAME] : 'TASA LOC', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2 },
    [con.FREE_RISK_RATE] : { [con.ID] : [con.FREE_RISK_RATE], [con.NAME] : 'TASA LIBRE DE RIESGO' },
    [con.OPTION_D1_HIST] : { [con.ID] : [con.OPTION_D1_HIST], [con.NAME] : 'D1 HISTORICO' },
    [con.OPTION_D2_HIST] : { [con.ID] : [con.OPTION_D2_HIST], [con.NAME] : 'D2 HISTORICO' },
    [con.OPTION_N_D1_HIST] : { [con.ID] : [con.OPTION_N_D1_HIST], [con.NAME] : 'NORMAL D1 HISTORICO' },
    [con.OPTION_N_D2_HIST] : { [con.ID] : [con.OPTION_N_D2_HIST], [con.NAME] : 'NORMAL D2 HISTORICO' },
    [con.OPTION_N_NEG_D1_HIST] : { [con.ID] : [con.OPTION_N_NEG_D1_HIST], [con.NAME] : 'NORMAL INVERSO D2 HISTORICO' },
    [con.OPTION_N_NEG_D2_HIST] : { [con.ID] : [con.OPTION_N_NEG_D2_HIST], [con.NAME] : 'NORMAL INVERSO D2 HISTORICO' },
    [con.DELTA_CALL] : { [con.ID] : [con.DELTA_CALL], [con.NAME] : 'DELTA CALL' },
    [con.DELTA_PUT] : { [con.ID] : [con.DELTA_PUT], [con.NAME] : 'DELTA PUT' },
    [con.DELTA] : { [con.ID] : [con.DELTA], [con.NAME] : 'DELTA', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2 },
    [con.VOLATILITY_CURVE] : { [con.ID] : [con.VOLATILITY_CURVE], [con.NAME] : 'CURVA VOL', [con.TYPE] : con.PERCENTAGE, [con.EDITABLE] : false, [con.DECIMAL_SCALE] : 2},
    [con.OPTION_D1_VOL] : { [con.ID] : [con.OPTION_D1_VOL], [con.NAME] : 'D1 VOLATILIDAD' },
    [con.OPTION_D2_VOL] : { [con.ID] : [con.OPTION_D2_VOL], [con.NAME] : 'D2 VOLATILIDAD' },
    [con.OPTION_N_D1_VOL] : { [con.ID] : [con.OPTION_N_D1_VOL], [con.NAME] : 'NORMAL D2 VOLATILIDAD' },
    [con.OPTION_N_D2_VOL] : { [con.ID] : [con.OPTION_N_D2_VOL], [con.NAME] : 'NORMAL D2 VOLATILIDAD' },
    [con.OPTION_VALUE] : { [con.ID] : [con.OPTION_VALUE], [con.NAME] : 'VALORACIÓN DE LA OPCION' },
    [con.ASSESSMENT] : { [con.ID] : [con.ASSESSMENT], [con.NAME] : 'VALORACIÓN', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false },
    [con.PREMIUM_GAINS] : { [con.ID] : [con.PREMIUM_GAINS], [con.NAME] : 'DIRECCION PRIMA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false },
    [con.PREMIUM_BALANCE] : { [con.ID] : [con.PREMIUM_BALANCE], [con.NAME] : 'BALANCE PRIMA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false },
    [con.TOTAL_ASSESSMENT] : { [con.ID] : [con.TOTAL_ASSESSMENT], [con.NAME] : 'VALORACIÓN + PRIMA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false },
    [con.ASSESSMENT_RATE] : { [con.ID] : [con.ASSESSMENT_RATE], [con.NAME] : 'TASA', [con.TYPE] : con.MONEY, [con.CURRENCY] : con.MONEY_COP, [con.EDITABLE] : false },

}      

export const selectedOptionCoverageColumns = [ { [con.ID] : con.EXPIRATION_DATE },
                                               { [con.ID] : con.OPENING_DATE },
                                               { [con.ID] : con.AMOUNT },
                                               { [con.ID] : con.OPENING_SPOT },
                                               { [con.ID] : con.STRIKE },
                                               { [con.ID] : con.PREMIUM },
                                               { [con.ID] : con.OPTION_TYPE },
                                               { [con.ID] : con.COVERAGE_TYPE },
                                               { [con.ID] : con.COUNTERPARTY },
                                               { [con.ID] : con.COMMENT},
                                               { [con.ID] : con.STATE}]


// Alert columns
export const allAlertMarketColumns = {
    [con.ACCOUNT_TYPE] : {[con.NAME] : 'Tipo', [con.TYPE] :  con.MULTIPLE_SELECTION, [con.ID] : con.ACCOUNT_TYPE, [con.OPTIONS] : ['Cuenta de Compensación','Cobertura Natural'], [con.VALUE_TRANSLATOR] : translateParameter, [con.EDITABLE] : false },
    [con.COMMENT] :{ [con.NAME] : 'Comentario',  [con.TYPE] :  con.TEXT, [con.ID] : con.COMMENT},
    [con.AMOUNT] : { [con.ID] : con.AMOUNT, [con.NAME] : 'Monto', [con.TYPE] :  con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.ALLOW_DECIMALS] : true}
}

export const selectedMarketAlertColumns = [{ [con.ID] : con.ACCOUNT_TYPE },
                                           { [con.ID] : con.COMMENT },
                                           { [con.ID] : con.AMOUNT }]


// Account columns
export const allAccountColumns = {
    [con.ACCOUNT_TYPE] : {[con.NAME] : 'Tipo', [con.TYPE] :  con.MULTIPLE_SELECTION, [con.ID] : con.ACCOUNT_TYPE, [con.OPTIONS] : ['Cuenta de Compensación','Cobertura Natural'], [con.VALUE_TRANSLATOR] : translateParameter, [con.EDITABLE] : false },
    [con.COMMENT] :{ [con.NAME] : 'Comentario',  [con.TYPE] :  con.TEXT, [con.ID] : con.COMMENT},
    [con.AMOUNT] : { [con.ID] : con.AMOUNT, [con.NAME] : 'Monto', [con.TYPE] :  con.MONEY, [con.CURRENCY] : con.MONEY_USD, [con.ALLOW_DECIMALS] : true}
}

export const selectedAccountColumns = [{ [con.ID] : con.ACCOUNT_TYPE },
                                       { [con.ID] : con.COMMENT },
                                       { [con.ID] : con.AMOUNT }]



export const prepareForExport = (transactionSummaryObject, selectedColumns) => {

    const arr = Object.values(transactionSummaryObject).map(ob => {
        let newOb = {}
        // Adjust Column Names
        selectedColumns.forEach(col => {

            // Checks if value needs translation
            let translate = (val) => val
            if(col[con.TYPE] === con.MULTIPLE_SELECTION)
                translate = col[con.VALUE_TRANSLATOR]
            
            newOb[col[con.NAME]] = translate(ob[col[con.ID]])
        })
            
        return(newOb)
    })

    return(arr)


}

// Support Methods
// ---------------------------

// Create empty dataFrame
const createEmptyDataFrame = () =>
{
    // NOTE: At the time of implementation, there is no way to create an empty dataframe (I know!!!).
    // This method will return a null value for now.

    return(null)
}

const sortByDate = (df, date_column) =>
{   
     // NOTE: At the time of implementation, there is a bug when sorting by date
     // This method does it manually, but can be overwritten with the proper method once the bug is fixed
    const temp_col = "TEMPORAL___COLUMN"    


    // Creates dummy column
    let dateList = df[date_column].dt["$dateObjectArray"]
    let min_date = Math.min(...dateList)
    df = df.addColumn(temp_col, dateList.map((d) => d - min_date));

    // Sorts by the column
    df = df.sortValues(temp_col)

    // Resets index
    df = df.resetIndex()

    // Drops Column
    df = df.drop({columns : [temp_col]})

    return(df)

}

// Method for setting a new column
// This method was created because the addColumn did not behave correctly if the column already existed
const setColumn = (df, col_name, new_values) =>
{
    df = df.drop({columns :[col_name]})
    df = df.addColumn(col_name, new_values)

    return(df)

}


const createMockExposure = (current_date) =>
{
    return({
        [con.EXPIRATION_DATE] : dateFun.formatDate(dateFun.addDays(current_date,1)),
        [con.AMOUNT] : 0,
        [con.COMMENT] : "",
        [con.STATE] : con.ACTIVE
    })
}


const createMockFwdCoverage = (current_date) =>
{
    return({
        [con.EXPIRATION_DATE] : dateFun.formatDate(dateFun.addDays(current_date,1)),
        [con.OPENING_DATE] : dateFun.formatDate(dateFun.addDays(current_date,1)),
        [con.AMOUNT] : 0,
        [con.RATE] : 0,
        [con.OPENING_SPOT] : 0,
        [con.COMMENT] : "",         
        [con.STATE] : con.ACTIVE
    })
}

const createMockOptionCoverage = (current_date) => {
      
        return({
            [con.EXPIRATION_DATE] : dateFun.formatDate(dateFun.addDays(current_date,1)),
            [con.OPENING_DATE] : dateFun.formatDate(dateFun.addDays(current_date,1)),
            [con.COVERAGES] : 0,  
            [con.AMOUNT] : 0,  
            [con.STRIKE] : 0,  
            [con.PREMIUM_GAINS] : 0,  
            [con.STATE] : con.ACTIVE
        })
    }


 const computeDirectCoverageAverage = (trans_df) =>
 {

    let exp = trans_df[con.EXPOSURE_AMOUNT]
    let cov = trans_df[con.COVERAGE_AMOUNT]
    let counter_fwd = trans_df[con.FWD_COUNTER_COVERAGE_AMOUNT]
    let counter_buy_call = trans_df[con.COUNTER_BUY_CALL_DELTA_AMOUNT]
    let counter_sell_call = trans_df[con.COUNTER_SELL_CALL_DELTA_AMOUNT]
    let counter_buy_put = trans_df[con.COUNTER_BUY_PUT_DELTA_AMOUNT]
    let counter_sell_put = trans_df[con.COUNTER_SELL_PUT_DELTA_AMOUNT]
    let counter = counter_fwd.add(counter_buy_call).add(counter_sell_call).add(counter_buy_put).add(counter_sell_put)
    let covMcount = cov.sub(counter)

    // Direct
    let coverage_percentage = covMcount.div(exp.add(epsylon))

    // Sets 0 coverage when exposure is 0
    coverage_percentage = coverage_percentage.mul(exp.gt(0))

    // Sets 1 coverage when coverage is positive and exposures is zero
    coverage_percentage = coverage_percentage.add(covMcount.gt(0).mul(exp.eq(0)))

    // Sets -1 coverage when counter coverage is positive and exposures and coverage is zero
    coverage_percentage = coverage_percentage.add(covMcount.lt(0).mul(-1).mul(exp.eq(0)))

    // coverage_percentage = coverage_percentage.add((counter.mul(-1).div(exp.add(epsylon))).mul(exp.gt(0)))
    
    return (coverage_percentage)
 }


 const computeCounterCoverageAverage = (trans_df) =>
 {

    let exp = trans_df[con.EXPOSURE_AMOUNT]
    let counter_cov = trans_df[con.FWD_COUNTER_COVERAGE_AMOUNT]
  
    let counter_coverage_percentage = exp.sub(counter_cov).div(exp.add(epsylon)).mul(-1).add(1)

    // Sets 0 coverage when exposure is 0
    counter_coverage_percentage = counter_coverage_percentage.mul(exp.gt(0))

    // Sets 1 coverage when coverage is positive and exposures is zero
    counter_coverage_percentage = counter_coverage_percentage.add(counter_cov.gt(0).mul(exp.eq(0)))

    return(counter_coverage_percentage)
 }
 

