import React, { useReducer, useState, useEffect } from 'react';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import Modal from '../../templates/popups/Modal';
import { createNotification } from '../../templates/notifications/Notifications';
import { TextAreaElement } from '../../templates/FormElement';
import LoadingIcon from '../../templates/loading/LoadingIcon';
import { getToday, addDays, parseDate, formatDate } from '../../utils/dateFunctions';
import { CoverageFWD } from '../../store/actions/transactions';
import * as con from '../../GlobalConstants';
import { reducer as columnReducer, initialState, areValuesValid } from './forwardsTableLogic';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Button } from '@blueprintjs/core';
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import './ForwardsTable.css';
import { COUNTERPARTIES_NAMES } from '../../utils/translateFunctions';

const InlineEditableCell = ({ value, onChange, formatFn, inputProps, style, className }) => {
  const [editing, setEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleBlur = () => {
    setEditing(false);
    if (onChange) {
      onChange(tempValue);
    }
  };

  const handleClick = () => {
    setEditing(true);
  };

  const handleChange = (e) => {
    setTempValue(e.target.value);
  };

  return (
    <div 
      style={{ display: 'inline-flex', alignItems: 'center', ...style, cursor: editing ? 'text' : 'pointer' }}
      className={className}
      onClick={() => { if (!editing) handleClick(); }}
    >
      {editing ? (
        <input
          {...inputProps}
          value={tempValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <span>{formatFn ? formatFn(value) : value}</span>
      )}
    </div>
  );
};

const formatNumber = (value) => {
  const num = parseFloat(value);
  if (isNaN(num)) return "0.00";
  return num.toLocaleString('en-US', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  });
};

const formatDecimal = (value, rowId) => {
  return formatNumber(value);
};

const formatPercentage = (value) => {
  const num = parseFloat(value);
  if (isNaN(num)) return "0.00%";
  return `${(num * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`;
};

const getCurrencyUnit = (rowId) => {
  if (rowId === 'nominal') return " USD";
  if (['spot', 'refTasa', 'bankSpot', 'bankForward', 'fwdPoints', 'copDevaluationDiff', 'spotDiff', 'totalCopDiff', 'pAndG'].includes(rowId))
    return " COP";
  return "";
};

const rowDefinitions = [
  { id: 'title', label: 'Cotización', group: null },
  { id: 'counterparty', label: 'Contraparte', group: null },
  { id: 'empty_counterparty', label: '', group: null, invisible: true }, 
  { id: 'type', label: 'Tipo', group: null },
  { id: 'executionDate', label: 'Fecha de Ejecución', group: null },
  { id: 'empty_executionDate', label: '', group: null, invisible: true },
  { id: 'spot', label: 'SPOT', group: null },
  { id: 'nominal', label: 'Nominal', group: null },
  { id: 'daysToExpiry', label: 'Días Para Vencimiento', group: null },
  { id: 'expirationDate', label: 'Fecha Vencimiento', group: null },
  { id: 'empty_expirationDate', label: '', group: null, invisible: true },
  { id: 'refTasa', label: 'Tasa FWD', group: 'Devaluación Ref.' },
  { id: 'refDevaluation', label: 'Devaluación', group: 'Devaluación Ref.' },
  { id: 'empty_refDevaluation', label: '', group: null, invisible: true },
  { id: 'bankSpot', label: 'SPOT Banco', group: 'Cotización Banco' },
  { id: 'bankForward', label: 'Tasa FWD', group: 'Cotización Banco' },
  { id: 'bankDevaluation', label: 'Devaluación', group: 'Cotización Banco' },
  { id: 'empty_bankDevaluation', label: '', group: null, invisible: true },
  { id: 'fwdPoints', label: 'Puntos Forward de Ref.', group: null },
  { id: 'fwdPointDiff', label: 'Dif. en Puntos FWD (Negativo = Costo)', group: null },
  { id: 'copDevaluationDiff', label: 'Dif. Curva Fwd Banco (PxD)', group: null },
  { id: 'spotDiff', label: 'Dif. Spot Banco (PxD)', group: null },
  { id: 'totalCopDiff', label: 'Dif. Pesos x Dólar total', group: null },
  { id: 'pAndG', label: 'Dif. Total Ref. - Banco', group: null },
  { id: 'commands', label: 'Comandos', group: null }
];

const multiColumnReducer = (state, action) => {
  if (action.type === "ADD_COLUMN") {
    return [...state, initialState()];
  }
  if (action.type === "REMOVE_COLUMN") {
    return state.slice(0, state.length - 1);
  }
  return state.map((colState, idx) => {
    if (idx === action.col) {
      return columnReducer(colState, action);
    }
    return colState;
  });
};

const inlineInputUnitContainer = {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '2px',
  background: 'inherit',
  boxSizing: 'border-box'
};

const smallInputStyle = {
  width: '90px',
  border: 'none',
  textAlign: 'center',
  background: 'inherit',
  padding: '2px',
  margin: 0
};

const cellWithToggleContainer = {
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box'
};

const toggleStyle = {
  position: 'absolute',
  top: '50%',
  right: '0',
  transform: 'translateY(-50%)'
};

export const ForwardsTable = ({ numberOfColumns = 3 }) => {
  const [columnsState, dispatch] = useReducer(
    multiColumnReducer,
    Array.from({ length: numberOfColumns }, () => initialState())
  );
  const [modalStates, setModalStates] = useState(
    Array.from({ length: numberOfColumns }, () => ({ addingCoverage: false, comment: '' }))
  );
  const [spotManual, setSpotManual] = useState(
    Array.from({ length: numberOfColumns }, () => false)
  );
  
  const updateModalState = (colIndex, newState) => {
    setModalStates(prev => {
      const updated = [...prev];
      updated[colIndex] = { ...updated[colIndex], ...newState };
      return updated;
    });
  };
  
  const coverageInsertionLoadingParameter = RefNestedApplicationParameterHook(con.TRANSACTIONS_INSERTED, con.COVERAGES_FWD);
  
  const handleAddCoverage = (colIndex) => {
    const state = columnsState[colIndex];
    const { comment } = modalStates[colIndex];
    let coverage = {
      [con.OPENING_DATE]: formatDate(state[con.CALCULATOR_EXECUTION_DATE]),
      [con.EXPIRATION_DATE]: formatDate(state[con.CALCULATOR_EXPIRATION_DATE]),
      [con.AMOUNT]: state[con.CALCULATOR_NOMINAL],
      [con.RATE]: state[con.CALCULATOR_QUOTED_FORWARD_RATE],
      [con.OPENING_SPOT]: state[con.CALCULATOR_BANK_SPOT],
      [con.COMMENT]: comment || "",
      [con.COUNTERPARTY]: state[con.CALCULATOR_COUNTERPARTY] || con.BLANK,
      [con.COVERAGE_TYPE]: state[con.CALCULATOR_INSTRUMENT_TYPE],
      [con.STATE]: con.ACTIVE
    };
    CoverageFWD.add(coverage, (trans) => {
      CoverageFWD.addFromCalculatorAnnotation(trans[con.ID]);
      updateModalState(colIndex, { addingCoverage: false, comment: '' });
      createNotification("Cobertura agregada correctamente");
    });
  };
  
  const formatInputDate = (date) => {
    if (!date) return "";
    if (typeof date.toISO === "function") {
      return date.toISODate();
    }
    if (date instanceof Date) {
      return date.toISOString().substring(0, 10);
    }
    return date;
  };
  
  const getEditableValue = (value) => {
    let num = parseFloat(value);
    if (isNaN(num)) return "";
    return num.toFixed(2);
  };
  
  const renderHeaderCells = (rowDef, rowIndex) => {
    if (rowDef.invisible) {
      return [<td key="invisible" className="invisible-cell" colSpan={2}></td>];
    }
    const rowClass = rowIndex === 0 ? "first-row" : (rowIndex % 2 === 1 ? "dark-blue" : "light-blue");
    if (rowDef.group) {
      if (rowIndex === 0 || !rowDefinitions[rowIndex - 1].group || rowDefinitions[rowIndex - 1].group !== rowDef.group) {
        let rowSpan = 1;
        for (let j = rowIndex + 1; j < rowDefinitions.length; j++) {
          if (rowDefinitions[j].group === rowDef.group) rowSpan++;
          else break;
        }
        return [
          <td key="group" className={`header-cell ${rowClass}`} rowSpan={rowSpan}>
            {rowDef.group}
          </td>,
          <td key="sub" className={`header-cell ${rowClass}`}>
            {rowDef.label}
          </td>
        ];
      } else {
        return [
          <td key="sub" className={`header-cell ${rowClass}`}>
            {rowDef.label}
          </td>
        ];
      }
    } else {
      return [
        <td key="single" className={`header-cell ${rowClass}`} colSpan={2}>
          {rowDef.label}
        </td>
      ];
    }
  };
  
  const renderDataCell = (rowDef, colIndex, rowIndex) => {
    if (rowDef.invisible) {
      return <td className="invisible-cell" key="invisible"></td>;
    }
    const state = columnsState[colIndex];
    const rowClass = rowIndex === 0 ? "first-row" : (rowIndex % 2 === 1 ? "dark-blue" : "light-blue");
    const staticStyle = rowClass === "first-row"
      ? { backgroundColor: "#f2b800", color: "black", textAlign: "center" }
      : (rowClass === "dark-blue"
          ? { backgroundColor: "#1f2128", color: "white", textAlign: "center" }
          : { backgroundColor: "#414453", color: "white", textAlign: "center" });
    const editableStyle = { backgroundColor: "#f2b800", color: "black", textAlign: "center" };
  
    switch (rowDef.id) {
      case 'title':
        return <td style={staticStyle} key="title">{`Forward ${colIndex + 1}`}</td>;
        
      case 'counterparty':
        return (
          <td style={editableStyle} key="counterparty">
            <select 
              value={state[con.CALCULATOR_COUNTERPARTY] || con.BLANK}
              onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_COUNTERPARTY, value: e.target.value })}
              style={{ background: "#f2b800", color: "black", textAlign: "center", border: "none" }}
            >
              {con.COUNTERPARTIES.map((cp, index) => (
                <option key={index} value={cp}>
                  {COUNTERPARTIES_NAMES[cp] || cp}
                </option>
              ))}
            </select>
          </td>
        );
        
      case 'type':
        return (
          <td style={editableStyle} key="type">
            <select 
              value={state[con.CALCULATOR_INSTRUMENT_TYPE] || con.BUY}
              onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_INSTRUMENT_TYPE, value: e.target.value })}
              style={{ background: "#f2b800", color: "black", textAlign: "center", border: "none" }}
            >
              <option value={con.BUY}>Compra</option>
              <option value={con.SELL}>Venta</option>
            </select>
          </td>
        );
        
      case 'executionDate':
        return (
          <td style={staticStyle} key="executionDate">
            <input 
              type="date" 
              value={formatInputDate(state[con.CALCULATOR_EXECUTION_DATE] || getToday())}
              onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EXECUTION_DATE, value: parseDate(e.target.value) })}
              style={{ background: "transparent", color: "inherit", textAlign: "center", border: "none" }}
            />
          </td>
        );
        
      case 'spot': {
         const manual = spotManual[colIndex];
         return (
           <td style={manual ? editableStyle : staticStyle} key="spot">
             <div style={cellWithToggleContainer}>
               <div style={inlineInputUnitContainer}>
                 {manual ? (
                   <>
                     <input 
                       type="number" 
                       value={getEditableValue(state[con.CALCULATOR_LOCAL_SPOT] || 0)}
                       onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_LOCAL_SPOT, value: parseFloat(e.target.value) })}
                       style={smallInputStyle}
                     />
                     <span>{getCurrencyUnit('spot')}</span>
                   </>
                 ) : (
                   <span>{formatNumber(state[con.CALCULATOR_LOCAL_SPOT])}{getCurrencyUnit('spot')}</span>
                 )}
               </div>
               <div style={toggleStyle}>
                 <Toggle
                   checked={manual}
                   onChange={e => {
                     const newVal = e.target.checked;
                     let newSpotManual = [...spotManual];
                     newSpotManual[colIndex] = newVal;
                     setSpotManual(newSpotManual);
                   }}
                   icons={false}
                 />
               </div>
             </div>
           </td>
         );
      }
      
      case 'nominal':
        return (
          <td style={editableStyle} key="nominal">
            <div style={inlineInputUnitContainer}>
              <InlineEditableCell 
                value={state[con.CALCULATOR_NOMINAL] || 0}
                onChange={(newVal) => 
                  dispatch({ col: colIndex, type: con.CALCULATOR_NOMINAL, value: parseFloat(newVal) })
                }
                formatFn={formatNumber}
                inputProps={{ type: 'number', style: smallInputStyle }}
              />
              <span>{getCurrencyUnit('nominal')}</span>
            </div>
          </td>
        );
        
      case 'daysToExpiry': {
         const editing = state[con.CALCULATOR_EDITING_DAYS];
         return (
           <td style={editing ? editableStyle : staticStyle} key="daysToExpiry">
             <div style={cellWithToggleContainer}>
               <div style={inlineInputUnitContainer}>
                 {editing ? (
                   <input 
                     type="number" 
                     value={getEditableValue(state[con.CALCULATOR_EXPIRY_DAYS] || 0)}
                     onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EXPIRY_DAYS, value: parseInt(e.target.value, 10) })}
                     style={smallInputStyle}
                   />
                 ) : (
                   <span>{formatNumber(state[con.CALCULATOR_EXPIRY_DAYS])}</span>
                 )}
               </div>
               <div style={toggleStyle}>
                 <Toggle
                   checked={editing}
                   onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EDITING_DAYS, value: e.target.checked })}
                   icons={false}
                 />
               </div>
             </div>
           </td>
         );
      }
      
      case 'expirationDate': {
         const editing = state[con.CALCULATOR_EDITING_DAYS];
         return (
           <td style={editing ? staticStyle : editableStyle} key="expirationDate">
             <div style={cellWithToggleContainer}>
               <div style={inlineInputUnitContainer}>
                 {!editing ? (
                   <input 
                     type="date" 
                     value={formatInputDate(state[con.CALCULATOR_EXPIRATION_DATE] || addDays(getToday(), 90))}
                     onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EXPIRATION_DATE, value: parseDate(e.target.value) })}
                     style={{ width: 'auto', textAlign: 'center', border: 'none', background: 'inherit' }}
                   />
                 ) : (
                   <span>{formatInputDate(state[con.CALCULATOR_EXPIRATION_DATE] || addDays(getToday(), 90))}</span>
                 )}
               </div>
               <div style={toggleStyle}>
                 <Toggle
                   checked={!editing}
                   onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EDITING_DAYS, value: !e.target.checked })}
                   icons={false}
                 />
               </div>
             </div>
           </td>
         );
      }
      
      case 'refTasa':
         return (
           <td style={staticStyle} key="refTasa">
             <span>{formatNumber(state[con.CALCULATOR_REFERENCE_FORWARD_RATE])}{getCurrencyUnit('refTasa')}</span>
           </td>
         );
         
      case 'refDevaluation':
         return (
           <td style={staticStyle} key="refDevaluation">
             <span>{formatPercentage(state[con.CALCULATOR_REFERENCE_DEVALUATION])}</span>
           </td>
         );
         
      case 'bankSpot': {
         return (
           <td style={editableStyle} key="bankSpot">
             <div style={inlineInputUnitContainer}>
               <InlineEditableCell 
                 value={state[con.CALCULATOR_BANK_SPOT] || 0}
                 onChange={(newVal) => dispatch({ col: colIndex, type: con.CALCULATOR_BANK_SPOT, value: parseFloat(newVal) })}
                 formatFn={formatNumber}
                 inputProps={{ type: 'number', style: smallInputStyle }}
               />
               <span>{getCurrencyUnit('bankSpot')}</span>
             </div>
           </td>
         );
      }
      
      case 'bankForward': {
         const editing = state[con.CALCULATOR_EDITING_QUOTED_FORWARD];
         return (
           <td style={editing ? editableStyle : staticStyle} key="bankForward">
             <div style={cellWithToggleContainer}>
               <div style={inlineInputUnitContainer}>
                 {editing ? (
                   <>
                     <input 
                       type="number" 
                       value={getEditableValue(state[con.CALCULATOR_QUOTED_FORWARD_RATE] || 0)}
                       onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_QUOTED_FORWARD_RATE, value: parseFloat(e.target.value) })}
                       style={smallInputStyle}
                     />
                     <span>{getCurrencyUnit('bankForward')}</span>
                   </>
                 ) : (
                   <span>{formatDecimal(state[con.CALCULATOR_QUOTED_FORWARD_RATE], 'bankForward')}{getCurrencyUnit('bankForward')}</span>
                 )}
               </div>
               <div style={toggleStyle}>
                 <Toggle
                   checked={editing}
                   onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EDITING_QUOTED_FORWARD, value: e.target.checked })}
                   icons={false}
                 />
               </div>
             </div>
           </td>
         );
      }
      
      case 'bankDevaluation': {
         const editing = state[con.CALCULATOR_EDITING_QUOTED_FORWARD];
         return (
           <td style={!editing ? editableStyle : staticStyle} key="bankDevaluation">
             <div style={cellWithToggleContainer}>
               <div style={inlineInputUnitContainer}>
                 {!editing ? (
                   <>
                     <input 
                       type="number" 
                       value={getEditableValue((state[con.CALCULATOR_QUOTED_DEVALUATION] || 0) * 100)}
                       onChange={e => {
                         const newVal = parseFloat(e.target.value);
                         dispatch({ col: colIndex, type: con.CALCULATOR_QUOTED_DEVALUATION, value: newVal / 100 });
                       }}
                       style={smallInputStyle}
                     />
                     <span>%</span>
                   </>
                 ) : (
                   <span>{formatDecimal((state[con.CALCULATOR_QUOTED_DEVALUATION] || 0) * 100, 'bankDevaluation')}%</span>
                 )}
               </div>
               <div style={toggleStyle}>
                 <Toggle
                   checked={!editing}
                   onChange={e => dispatch({ col: colIndex, type: con.CALCULATOR_EDITING_QUOTED_FORWARD, value: !e.target.checked })}
                   icons={false}
                 />
               </div>
             </div>
           </td>
         );
      }
      
      case 'fwdPoints':
         return (
           <td style={staticStyle} key="fwdPoints">
             <span>{formatNumber(state[con.CALCULATOR_FWD_POINTS])}{getCurrencyUnit('fwdPoints')}</span>
           </td>
         );
         
      case 'fwdPointDiff':
         return (
           <td style={staticStyle} key="fwdPointDiff">
             <span>{formatNumber(state[con.CALCULATOR_FWD_POINT_DIFF])}</span>
           </td>
         );
         
      case 'copDevaluationDiff':
         return (
           <td style={staticStyle} key="copDevaluationDiff">
             <span>{formatNumber(state[con.CALCULATOR_COP_DEVALUATION_DIFF])}{getCurrencyUnit('copDevaluationDiff')}</span>
           </td>
         );
         
      case 'spotDiff':
         return (
           <td style={staticStyle} key="spotDiff">
             <span>{formatNumber(state[con.CALCULATOR_SPOT_DIFF])}{getCurrencyUnit('spotDiff')}</span>
           </td>
         );
         
      case 'totalCopDiff':
         return (
           <td style={staticStyle} key="totalCopDiff">
             <span>{formatNumber(state[con.CALCULATOR_TOTAL_COP_DIFF])}{getCurrencyUnit('totalCopDiff')}</span>
           </td>
         );
         
      case 'pAndG':
         return (
           <td style={staticStyle} key="pAndG">
             <span>{formatNumber(state[con.CALCULATOR_P_AND_G])}{getCurrencyUnit('pAndG')}</span>
           </td>
         );
         
      case 'commands':
         return (
           <td style={staticStyle} key="commands">
             <Button 
               text="Agregar a Coberturas"
               icon="plus"
               disabled={!areValuesValid(state)[0]}
               onClick={() => updateModalState(colIndex, { addingCoverage: true })}
             />
             <Modal 
               show={modalStates[colIndex].addingCoverage} 
               doAccept={null} 
               showCancel={false} 
               doCancel={() => updateModalState(colIndex, { addingCoverage: false })}
             >
               <div>
                 <h3>Agregar Cobertura</h3>
                 <TextAreaElement 
                   name="Comentario" 
                   value={modalStates[colIndex].comment} 
                   setValue={(val) => updateModalState(colIndex, { comment: val })}
                   disabled={coverageInsertionLoadingParameter[con.STATUS] === con.LOADING} 
                 />
                 <div className="confirmCancelParameterBox" style={{ marginTop: "15px" }}>
                   {coverageInsertionLoadingParameter[con.STATUS] === con.LOADING ? (
                     <LoadingIcon />
                   ) : (
                     <div>
                       <Button 
                         icon="upload" 
                         text="Agregar Cobertura" 
                         onClick={() => handleAddCoverage(colIndex)} 
                       />
                       <Button 
                         icon="cross" 
                         text="Cancelar" 
                         onClick={() => updateModalState(colIndex, { addingCoverage: false })}
                       />
                     </div>
                   )}
                 </div>
               </div>
             </Modal>
           </td>
         );
         
      default:
         return <td style={staticStyle} key="default"></td>;
    }
  };

  const groups = [];
  let currentGroup = [];
  rowDefinitions.forEach((rowDef, idx) => {
    if (rowDef.invisible) {
      if (currentGroup.length > 0) {
        groups.push({ type: 'visible', rows: currentGroup });
        currentGroup = [];
      }
      groups.push({ type: 'separator', rows: [{ rowDef, rowIndex: idx }] });
    } else {
      currentGroup.push({ rowDef, rowIndex: idx });
    }
  });
  if (currentGroup.length > 0) {
    groups.push({ type: 'visible', rows: currentGroup });
  }

  const handleAddColumn = () => {
    dispatch({ type: "ADD_COLUMN" });
    setModalStates(prev => [...prev, { addingCoverage: false, comment: '' }]);
    setSpotManual(prev => [...prev, false]);
  };

  const handleRemoveColumn = () => {
    dispatch({ type: "REMOVE_COLUMN" });
    setModalStates(prev => prev.slice(0, prev.length - 1));
    setSpotManual(prev => prev.slice(0, prev.length - 1));
  };

  return (
    <div className="forwards-table-wrapper">
      <div className="forwards-table-flex">
        <table className="forwards-table">
          {groups.map((group, groupIndex) => (
            <tbody key={groupIndex} className={group.type === 'visible' ? "subtable" : "separator"}>
              {group.rows.map(({ rowDef, rowIndex }) => (
                <tr key={rowDef.id} className={rowDef.invisible ? "invisible-row" : ""}>
                  {renderHeaderCells(rowDef, rowIndex)}
                  {Array.from({ length: columnsState.length }, (_, colIndex) => (
                    <React.Fragment key={colIndex}>
                      {renderDataCell(rowDef, colIndex, rowIndex)}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          ))}
        </table>
        <div className="table-controls">
          {columnsState.length === 2 && (
            <button onClick={handleAddColumn} className="control-button">
              <FaPlus />
            </button>
          )}
          {columnsState.length === 3 && (
            <>
              <button onClick={handleAddColumn} className="control-button">
                <FaPlus />
              </button>
              <button onClick={handleRemoveColumn} className="control-button">
                <FaMinus />
              </button>
            </>
          )}
          {columnsState.length === 4 && (
            <button onClick={handleRemoveColumn} className="control-button">
              <FaMinus />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForwardsTable;
