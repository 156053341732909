// TODO - Documentar
import React, { useEffect, useState } from 'react'

// Global constants
import * as con from '../../GlobalConstants'


// Style
import '../ComponentsGlobalStyle.css'
import { GenericElement } from '../../templates/FormElement';
import { ScenariosHook } from '../hooks/ScenariosHook';
import { RefModelParameterHook, StateModelParameterHook } from '../hooks/ModelParameterHooks';
import { translateBands } from '../../utils/translateFunctions';
import { RefSPOT, StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { AdvisorsHook, RefSelectedAdvisors } from '../hooks/AdvisorsHooks';
import { addAdvisor } from '../../store/actions/advisors';
import { round } from '../../GlobalFunctions';
import { getAnualChangeByGeneratingFunction } from '../../utils/scenarioFunctions';



export function ScenariosSelect() {

    const [selectedScenario, setSelectedScenario] = StateApplicationParameterHook(con.SELECTED_SCENARIO)    
    const scenarios = ScenariosHook()

    const spot = RefSPOT()

  return (
    < GenericElement type={con.MULTIPLE_SELECTION}
                            name={'Escenario'}
                            id={con.SELECTED_SCENARIO} 
                            value={selectedScenario} 
                            setValue={setSelectedScenario} 
                            values={Object.keys(scenarios)}
                            valueLabels={Object.values(scenarios).map((esc) => `${esc[con.NAME]} (${round(100*getAnualChangeByGeneratingFunction(spot, esc[con.GENERATING_FUNCTION]),2)}%)`)}
                            /> 
  )
}



export function BandSelect() {

    const [selectedBand, setSelectedBand] = StateApplicationParameterHook(con.SELECTED_BAND)

  return (
    < GenericElement type={con.MULTIPLE_SELECTION}
                         name={'Banda'}
                         id={con.SELECTED_BAND} 
                         value={selectedBand} 
                         setValue={setSelectedBand} 
                         values={con.BANDS}
                         valueLabels={con.BANDS.map((b) => translateBands(b))}
                         />  
  )
}

export function ShowDetailsCheckBox() {
  const [showDetails, setShowDetails] = StateApplicationParameterHook(con.SHOW_DETAILS);

  return (
    <GenericElement
      type={con.BOOLEAN}  
      name={"Ver detalles"}
      id={con.SHOW_DETAILS}
      value={showDetails}
      setValue={setShowDetails}
      label={"Ver columnas detalladas"}  
    />
  );
}

export function TermPeriodicity()
{
  const periodicity = RefModelParameterHook(con.PERIODICITY)

  const [termPeriodicity, setTermPeriodicity] = StateModelParameterHook(con.TERM_PERIODICITY)  
  let label_periodicity 
  switch (periodicity) {
      case con.PERIODICITY_WEEK:
        label_periodicity = "Semanas";
          break;
      case con.PERIODICITY_DAY:
        label_periodicity = "Dias";
          break;
      default:
        label_periodicity = "Meses";
  }

  const customSetValue = (val) =>
  {
    if(val > 0 && val <= 12 && val !== termPeriodicity)
    setTermPeriodicity(val)

  }

  return(< GenericElement type={con.NUMERIC}
                name={`Plazo en ${label_periodicity}`}
                id={con.TERM_PERIODICITY} 
                value={termPeriodicity} 
                setValue={(val) => customSetValue(val)}
                min={1}
                max={12}  
            />   )
}


export function AverageForPercentage()
{

  const [avgForPercentage, setAvgForPercentage] = StateModelParameterHook(con.AVERAGE_FOR_PERCENTAGE)
  
  return(< GenericElement type={con.PERCENTAGE}
            name={'Porcentaje para Promedios'}
            id={con.AVERAGE_FOR_PERCENTAGE} 
            value={avgForPercentage} 
            setValue={setAvgForPercentage} 
            />  )
}


export function ClientType()
{
  const [clientType, setClientType] = StateModelParameterHook(con.CLIENT_TYPE)

  return(
             < GenericElement type={con.MULTIPLE_SELECTION}
                         name={'Tipo Cliente'}
                         id={con.CLIENT_TYPE} 
                         value={clientType} 
                         setValue={(val) => setClientType(val)} 
                         values={[con.IMPORTER,con.EXPORTER]}
                         valueLabels={['Importador','Exportador']}
                         /> 
    
  )
}



export function Periodicity()
{
    const [periodicity, setPeriodicity] = StateModelParameterHook(con.PERIODICITY)

    return(
        < GenericElement type={con.MULTIPLE_SELECTION}
        name={'Periodicidad'}
        id={con.PERIODICITY} 
        value={periodicity} 
        setValue={(val) => setPeriodicity(val)} 
        values={[con.PERIODICITY_DAY,con.PERIODICITY_WEEK, con.PERIODICITY_MONTH]}
        valueLabels={['Dia','Semana','Mes']}
        /> 
      )
}

export function PassThrough()
{
    const [passthrough, setPassthrough] = StateModelParameterHook(con.PASSTHROUGH)
    const clientType= RefModelParameterHook(con.CLIENT_TYPE)

    return(
              < GenericElement type={con.PERCENTAGE}
                  name={'Passthrough'}
                  id={con.PASSTHROUGH} 
                  value={passthrough} 
                  setValue={(val) => setPassthrough(val)}
                  disabled={clientType === con.EXPORTER} 
                  />  

      )
}





export function VisualizationPeriodicity()
{

     // Model Parameters
    // -----------------
    const [periodicityVisualization, setPeriodicityVisualization] = StateApplicationParameterHook(con.VISUALIZATION_PERIODICITY)
    const periodicity = RefModelParameterHook(con.PERIODICITY)

    // Disabled radiobuttons
    const [dayDisabled, setDayDisabled] = useState(() => periodicity === con.PERIODICITY_MONTH || periodicity === con.PERIODICITY_WEEK)
    const [weekDisabled, setWeekDisabled] = useState(() => periodicity === con.PERIODICITY_MONTH )
    
    useEffect(() => {
      
      if(periodicity === con.PERIODICITY_MONTH)
      {
        setPeriodicityVisualization(con.PERIODICITY_MONTH)
        setDayDisabled(true)
        setWeekDisabled(true)
      }

      if(periodicity === con.PERIODICITY_WEEK)
      {
        if(periodicity === con.PERIODICITY_DAY)
          setPeriodicityVisualization(con.PERIODICITY_WEEK)

        setDayDisabled(true)
        setWeekDisabled(false)
      }

      if(periodicity === con.PERIODICITY_DAY)
      {
        setDayDisabled(false)
        setWeekDisabled(false)

      }

    }, [periodicity, setPeriodicityVisualization])

    

    
    return(<div title={"Este parámetro no afecta la periodicidad con la que se calcula los valores, solo su visualización, haciendo sumas ponderadas según el periodo seleccionado."}>
            <RadioGroup label="Periodicidad (visualización)"
              inline={true}
              onChange={(e) => setPeriodicityVisualization(e.currentTarget.value)}
              selectedValue={periodicityVisualization}>
              <Radio label="Mensual" value={con.PERIODICITY_MONTH} />            
              {!weekDisabled && <Radio label="Semanal" value={con.PERIODICITY_WEEK} disabled={weekDisabled}/>}
              {!dayDisabled && <Radio label="Diaria" value={con.PERIODICITY_DAY} disabled={dayDisabled}/>}
            
          </RadioGroup>
          </div>
          )
}

export function NonZeroRowsCheckBox()
{
  const [onlyNonZeroRows, setOnlyNonZeroRows] = StateApplicationParameterHook(con.ONLY_NON_ZERO_ROWS)

  return( < GenericElement type={con.BOOLEAN}
              name={"Filtrar Flujos"}
              id={con.ONLY_NON_ZERO_ROWS} 
              value={onlyNonZeroRows} 
              setValue={setOnlyNonZeroRows} 
              label={'Solo flujos no vacios'}                                    
              />  )
}

export function CarryOverCoverage()
{
  const [carryOverCoverage, setCarryOverCoverage] = StateApplicationParameterHook(con.CARRY_OVER_COVERAGE)

  return( < GenericElement type={con.BOOLEAN}
              name={"Manejo Coberturas"}
              id={con.ONLY_NON_ZERO_ROWS} 
              value={carryOverCoverage} 
              setValue={setCarryOverCoverage} 
              label={'Redistribuir Coberturas'}                                    
              />  )
}




export function AdvisorsAdd({disabled}) {

      const NoAdvisorSelected = '---------'

      const selectedAdvisorsObject = RefSelectedAdvisors()

      const [selectedAdvisorsIds, setSelectedAdvisorsIds] = useState(() => selectedAdvisorsObject.map(adv =>adv[con.ID]))

      useEffect(() => {
        
        setSelectedAdvisorsIds( selectedAdvisorsObject.map(adv =>adv[con.ID]))

      }, [selectedAdvisorsObject])
      
      
      const advisors = AdvisorsHook()

      const [currentAdvisor, setCurrentAdvisor] = useState(() => null)

      const [currentArray, setCurrentArray] = useState(() => [{[con.ID] : null, [con.NAME] : NoAdvisorSelected}].concat(advisors.filter((advisor) => !selectedAdvisorsIds.includes(advisor[con.ID]))))  

      useEffect(() => {        
        setCurrentArray( [{[con.ID] : null, [con.NAME] : NoAdvisorSelected}].concat(advisors.filter((advisor) => !selectedAdvisorsIds.includes(advisor[con.ID]))))
        setCurrentAdvisor(NoAdvisorSelected)
      }, [selectedAdvisorsIds, advisors])
      

    return (
      
      < GenericElement type={con.MULTIPLE_SELECTION}
                              name={'Agregar Asesor'}
                              id={con.ADVISORS} 
                              value={currentAdvisor} 
                              setValue={(id) => {
                                    if(id !== null && id !== NoAdvisorSelected)
                                    {
                                      setCurrentAdvisor(NoAdvisorSelected)
                                      addAdvisor(id)
                                    }
                                  }} 
                              values={currentArray.map(elem => elem[con.ID])}
                              valueLabels={currentArray.map(elem => elem[con.NAME])}
                              disabled={disabled}
                              /> 
    )
}

AdvisorsAdd.defaultProps = {
  disabled : false,
}


