// TODO - Documentar
import ForwardAssessmentControls from '../components/forwardAssessment/ForwardAssessmentControls'
import ForwardAssessmentTable from '../components/forwardAssessment/ForwardAssessmentTable'
import { AssessedForwardCoveragesHook } from '../components/hooks/TransactionsHooks'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import "./Pages.css"


function ForwardAssessmentPage() {  


      
  

    const [assessedFwdDictionary, assessmentSummary] = AssessedForwardCoveragesHook()

    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <ForwardAssessmentControls assessmentSummary={assessmentSummary} assessedFwdDictionary={assessedFwdDictionary}/>
            <ForwardAssessmentTable assessedFwdDictionary={assessedFwdDictionary}/>         
            <SimpleFillFooter />   
        </div>
    )
}

export default ForwardAssessmentPage