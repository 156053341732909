// forwardsTableLogic.js
// Funciones de lógica para la tabla de Forwards

import { addDays, daysBetweenDates, getToday} from '../../utils/dateFunctions';
import { computeDevaluationFromForwardValueAndSpot, computeForwardValueFromDevaluationAndSpot, getGeneratorDevaluationFunctionByDay } from '../../utils/forwardFunctions';
import * as con from '../../GlobalConstants';
import { getApplicationParameter } from '../../store/actions/applicationParameters';
import { getModelParameter } from '../../store/actions/modelParameters';
import { filterObjectByKeys } from '../../GlobalFunctions';

// Función para computar valores no editables
export const computeNonEditable = (parameters) => {
  const i = parameters[con.CALCULATOR_INSTRUMENT_TYPE] === con.SELL ? -1 : 1;
  const tempFWDPoints = i * (parameters[con.CALCULATOR_QUOTED_FORWARD_RATE] - parameters[con.CALCULATOR_LOCAL_SPOT]);
  const tempDifPuntosFWD = i * (parameters[con.CALCULATOR_REFERENCE_DEVALUATION] - parameters[con.CALCULATOR_QUOTED_DEVALUATION]);
  const tempDifSpot = i * (parameters[con.CALCULATOR_LOCAL_SPOT] - parameters[con.CALCULATOR_BANK_SPOT]);
  const tempDifPesosDevaluacion = (parameters[con.CALCULATOR_BANK_SPOT]) * (
    Math.pow((1 + parameters[con.CALCULATOR_REFERENCE_DEVALUATION]), (parameters[con.CALCULATOR_EXPIRY_DAYS] / con.DAYS_IN_YEAR_FORWARDS)) -
    Math.pow((1 + parameters[con.CALCULATOR_QUOTED_DEVALUATION]), (parameters[con.CALCULATOR_EXPIRY_DAYS] / con.DAYS_IN_YEAR_FORWARDS))
  );
  const tempDifPesosTotal = tempDifSpot + tempDifPesosDevaluacion;
  const tempPyG = tempDifPesosTotal * parameters[con.CALCULATOR_NOMINAL];

  return {
    [con.CALCULATOR_FWD_POINTS]: tempFWDPoints,
    [con.CALCULATOR_FWD_POINT_DIFF]: tempDifPuntosFWD,
    [con.CALCULATOR_SPOT_DIFF]: tempDifSpot,
    [con.CALCULATOR_COP_DEVALUATION_DIFF]: tempDifPesosDevaluacion,
    [con.CALCULATOR_TOTAL_COP_DIFF]: tempDifPesosTotal,
    [con.CALCULATOR_P_AND_G]: tempPyG,
  };
};

export const computeExpiryDays = (params) => {
  return daysBetweenDates(params[con.CALCULATOR_EXECUTION_DATE], params[con.CALCULATOR_EXPIRATION_DATE], false);
};

export const computeExpirationDate = (params) => {
  return addDays(params[con.CALCULATOR_EXECUTION_DATE], params[con.CALCULATOR_EXPIRY_DAYS]);
};

export const computeQuotedDevaluation = (params) => {
  let quotedForwardRate = params[con.CALCULATOR_QUOTED_FORWARD_RATE] || 0;
  let dev = computeDevaluationFromForwardValueAndSpot(quotedForwardRate, params[con.CALCULATOR_BANK_SPOT], params[con.CALCULATOR_EXPIRY_DAYS]);
  if (dev === undefined || isNaN(dev) || dev < 0) dev = 0;
  return dev;
};

export const computeQuotedFWDRate = (params) => {
  let val = computeForwardValueFromDevaluationAndSpot(params[con.CALCULATOR_QUOTED_DEVALUATION], params[con.CALCULATOR_BANK_SPOT], params[con.CALCULATOR_EXPIRY_DAYS]);
  if (val === undefined || isNaN(val) || val < 0) val = 0;
  return val;
};

export const reducer = (state, action) => {
  let value = action.value;
  let paramName = action.type;
  let newVals = { ...state };
  newVals[paramName] = value;

  if ([con.CALCULATOR_EDITING_DAYS, con.CALCULATOR_EDITING_QUOTED_FORWARD].includes(paramName))
      return newVals;

  let devaluationExtractionFunction = getGeneratorDevaluationFunctionByDay(
    newVals[con.CALCULATOR_LOCAL_FORWARD_CURVE][con.DAYS],
    newVals[con.CALCULATOR_LOCAL_FORWARD_CURVE][newVals[con.CALCULATOR_INSTRUMENT_TYPE]]
  );

  if ([con.CALCULATOR_EXECUTION_DATE, con.CALCULATOR_EXPIRATION_DATE].includes(paramName)) {
      if (!newVals[con.CALCULATOR_EXECUTION_DATE]) return newVals;
  }

  switch (paramName) {
      case con.CALCULATOR_INSTRUMENT_TYPE:
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          break;
      case con.CALCULATOR_LOCAL_FORWARD_CURVE:
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          break;
      case con.CALCULATOR_EXECUTION_DATE:
          if (value >= newVals[con.CALCULATOR_EXPIRATION_DATE]) {
              newVals[con.CALCULATOR_EXPIRATION_DATE] = getToday();
          }
          newVals[con.CALCULATOR_EXPIRY_DAYS] = computeExpiryDays(newVals);
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals);
          break;
      case con.CALCULATOR_EXPIRATION_DATE:
          if (value <= newVals[con.CALCULATOR_EXECUTION_DATE]) {
              value = getToday();
          }
          newVals[con.CALCULATOR_EXPIRATION_DATE] = value;
          newVals[con.CALCULATOR_EXPIRY_DAYS] = computeExpiryDays(newVals);
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals);
          break;
      case con.CALCULATOR_EXPIRY_DAYS:
          newVals[con.CALCULATOR_EXPIRATION_DATE] = computeExpirationDate(newVals);
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals);
          break;
      case con.CALCULATOR_LOCAL_SPOT:
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]);
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION],
            newVals[con.CALCULATOR_LOCAL_SPOT],
            newVals[con.CALCULATOR_EXPIRY_DAYS]
          );
          break;
      case con.CALCULATOR_BANK_SPOT:
          if (newVals[con.CALCULATOR_EDITING_QUOTED_FORWARD])
              newVals[con.CALCULATOR_QUOTED_DEVALUATION] = computeQuotedDevaluation(newVals);
          else
              newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals);
          break;
      case con.CALCULATOR_QUOTED_DEVALUATION:
          newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals);
          break;
      case con.CALCULATOR_QUOTED_FORWARD_RATE:
          newVals[con.CALCULATOR_QUOTED_DEVALUATION] = computeQuotedDevaluation(newVals);
          break;
      default:
          break;
  }

  let newNonEditable = computeNonEditable(newVals);
  newVals = { ...newVals, ...newNonEditable };

  return newVals;
};

export const areValuesValid = (vals) => {
  const toCheckInBatch = filterObjectByKeys(vals, [
    con.CALCULATOR_EXECUTION_DATE,
    con.CALCULATOR_EXPIRATION_DATE,
    con.CALCULATOR_NOMINAL,
    con.CALCULATOR_QUOTED_FORWARD_RATE,
    con.CALCULATOR_LOCAL_SPOT
  ]);

  let resp = [true, "Agregar Cobertura"];

  Object.keys(toCheckInBatch).forEach((key) => {
    let val = toCheckInBatch[key];
    if (isNaN(val) || val === null || val === undefined) {
      resp = [false, `El parámetro: ${key} no puede ser nulo`];
    }
  });

  if (!resp[0]) return resp;
  if (vals[con.CALCULATOR_NOMINAL] <= 0)
      return [false, "El valor Nominal no puede ser negativo"];

  return [true, "Agregar Cobertura"];
};

export const initialState = () => {
  let fwd = getApplicationParameter(con.FORWARD_CALCULATOR_FORWARD_CURVE);
  let sp = getModelParameter(con.SPREAD);
  let today = getToday();
  let defaultExpiryDays = 90;
  return {
    [con.CALCULATOR_INSTRUMENT_TYPE]: con.BUY,
    [con.CALCULATOR_EXECUTION_DATE]: today,
    [con.CALCULATOR_EXPIRY_DAYS]: defaultExpiryDays,
    [con.CALCULATOR_EXPIRATION_DATE]: addDays(today, defaultExpiryDays),
    [con.CALCULATOR_REFERENCE_DEVALUATION]: getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
    [con.CALCULATOR_REFERENCE_FORWARD_RATE]: computeForwardValueFromDevaluationAndSpot(
      getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
      getApplicationParameter(con.SPOT),
      defaultExpiryDays
    ),
    [con.CALCULATOR_NOMINAL]: 250000,
    [con.CALCULATOR_BANK_SPOT]: getApplicationParameter(con.SPOT),
    [con.CALCULATOR_QUOTED_DEVALUATION]: getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
    [con.CALCULATOR_QUOTED_FORWARD_RATE]: computeForwardValueFromDevaluationAndSpot(
      getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
      getApplicationParameter(con.SPOT),
      defaultExpiryDays
    ),
    [con.CALCULATOR_LOCAL_SPOT]: getApplicationParameter(con.SPOT),
    [con.CALCULATOR_LOCAL_FORWARD_CURVE]: fwd,
    [con.CALCULATOR_LOCAL_SPREAD]: sp,
    [con.CALCULATOR_EDITING_DAYS]: true,
    [con.CALCULATOR_EDITING_QUOTED_FORWARD]: false,
    [con.CALCULATOR_COUNTERPARTY]: con.BLANK,
    ...computeNonEditable({
      [con.CALCULATOR_INSTRUMENT_TYPE]: con.BUY,
      [con.CALCULATOR_EXECUTION_DATE]: today,
      [con.CALCULATOR_EXPIRY_DAYS]: defaultExpiryDays,
      [con.CALCULATOR_EXPIRATION_DATE]: addDays(today, defaultExpiryDays),
      [con.CALCULATOR_REFERENCE_DEVALUATION]: getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
      [con.CALCULATOR_REFERENCE_FORWARD_RATE]: computeForwardValueFromDevaluationAndSpot(
        getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
        getApplicationParameter(con.SPOT),
        defaultExpiryDays
      ),
      [con.CALCULATOR_NOMINAL]: 250000,
      [con.CALCULATOR_BANK_SPOT]: getApplicationParameter(con.SPOT),
      [con.CALCULATOR_QUOTED_DEVALUATION]: getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
      [con.CALCULATOR_QUOTED_FORWARD_RATE]: computeForwardValueFromDevaluationAndSpot(
        getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(defaultExpiryDays),
        getApplicationParameter(con.SPOT),
        defaultExpiryDays
      ),
      [con.CALCULATOR_LOCAL_SPOT]: getApplicationParameter(con.SPOT)
    })
  };
};
