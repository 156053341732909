import { Button } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import { makeAllManagementReport } from '../../utils/reportFunctions'


// Global constants
import * as con from '../../GlobalConstants'

// Style
import '../ComponentsGlobalStyle.css'

import { GenericElement } from '../../templates/FormElement';
import { formatDate, getToday, parseDate, minDate, maxDate, afterToday, isWeekEnd, addMonths} from '../../utils/dateFunctions';
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';

function AllManagementReport({columnLayout = false }) {


    // Application Parameters
    const AllManagementReport = RefApplicationParameterHook(con.ALL_MANAGEMENT_REPORT)
    
    // Start date
    const [startDate, setStartDate] = useState(() => formatDate(addMonths(getToday(), -3, true)));

    // End date
    const [endDate, setEndDate] = useState(() => formatDate(getToday()));   

    return (
        <div className="columnParameterBoxTop">

            <h3>Descargar Gestión de Todos los Clientes</h3> 

            <div className={columnLayout ? "columnParameterBox" :"smallParameterBox"} style={{marginTop : "0px"}}>
                <div style={{marginRight : "5px"}}> 
                < GenericElement type={con.DATE}
                            name={'Fecha Inicio'}
                            id={'start_date'} 
                            shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                            value={startDate} 
                            setValue={(val) => setStartDate(formatDate(minDate(parseDate(val), parseDate(endDate))))}                                                    
                            /> 
                </div>
                <div style={{marginLeft : "5px"}}> 
                < GenericElement type={con.DATE}
                                        name={'Fecha Fin'}
                                        id={'end_date'} 
                                        shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                                        value={endDate} 
                                        setValue={(val) => setEndDate(formatDate(maxDate(parseDate(val), parseDate(startDate))))} 
                                        /> 
                </div>
            </div>                            


            <div>
              <Button   disabled={AllManagementReport[con.GENERATING]} 
                        onClick={() => makeAllManagementReport({startDate : startDate, endDate: endDate})}
                        icon="download" text="Descargar Excel"></Button>  
            </div>
            <div style={{width : "20px", marginTop: "15px"}}>
            {
                AllManagementReport[con.GENERATING]
                && <LoadingIconSmall/> 
            }
            </div>
        </div>
    )
}

export default AllManagementReport