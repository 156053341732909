import React from 'react';
import { StaticTable } from '../../templates/tables/StaticTable';
import * as con from "../../GlobalConstants";
import "./../ComponentsGlobalStyle.css";
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { nonZeroTranactionFunction, prepareForExport, selectedTransactionCols } from '../../utils/transactionFunctions';
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook';
import { TransactionColumnsHook } from '../hooks/TransactionsHooks';
import { Button } from '@blueprintjs/core';
import { downloadExcelFromArray } from '../../utils/downloadFunctions';
import { buildTransactionsSummaryDownloadFileName } from '../../GlobalFunctions';
import { createNotification } from '../../templates/notifications/Notifications';
import TransactionExtractionLoadingIcons from '../transactions/TransactionExtractionLoadingIcons';
import { constructFileName } from '../../utils/reportFunctions';

export const TransactionsSummary = ({
  selectedColumns,
  fixedSize,
  forcedVisualizationPeriodicity,
  includeAllRows,
  filterByBands,
  includeDownloadButton,
  compress
}) => {

  const [transactionSummaryObject, summaryRow, computing, hasSummaryTable] =
    RefTransactionsSummaryTableHook({ forcedVisualizationPeriodicity });

  let transactionsCol = TransactionColumnsHook(selectedColumns, filterByBands);
  const showDetails = RefApplicationParameterHook(con.SHOW_DETAILS);

  if (!showDetails) {
    const columnsToHide = [
      con.FWD_COVERAGE_AMOUNT,
      con.FWD_COVERAGE_RATE,
      con.DIRECT_DELTA,
      con.OPTION_COVERAGE_AMOUNT,
      con.OPTION_COVERAGE_RATE,
      con.COUNTER_DELTA
    ];
    transactionsCol = transactionsCol.filter(col => !columnsToHide.includes(col[con.ID]));
  }

  const onlyNonZeroRows = RefApplicationParameterHook(con.ONLY_NON_ZERO_ROWS);
  const selectedBand = RefApplicationParameterHook(con.SELECTED_BAND);
  const transactionsLoaded = RefApplicationParameterHook(con.ALL_TRANSACTIONS_LOADED);

  return (
    <div style={{ marginBottom: "2vh" }}>
      {
        (!transactionsLoaded) ?
          <TransactionExtractionLoadingIcons />
          : computing ? <h4>Computando</h4>
            : (!hasSummaryTable) ? <h4>{con.NO_TRANSACTIONS_MESSAGE}</h4>
              : <div>
                  <StaticTable
                    columnArray={transactionsCol}
                    dataDictionary={transactionSummaryObject}
                    summaryRow={summaryRow}
                    fixedSize={fixedSize}
                    compress={compress}
                    filterFunction={ob =>
                      includeAllRows ||
                      !onlyNonZeroRows ||
                      nonZeroTranactionFunction(ob)
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    {includeDownloadButton &&
                      <Button
                        icon="download"
                        text="Descargar"
                        onClick={() => {
                          downloadExcelFromArray(
                            prepareForExport(transactionSummaryObject, transactionsCol),
                            constructFileName(
                              buildTransactionsSummaryDownloadFileName(selectedBand),
                              con.XLSX_FILE_TYPE
                            )
                          )
                          createNotification("Resumen descargado")
                        }}
                      />
                    }
                  </div>
                </div>
      }
    </div>
  );
}

TransactionsSummary.defaultProps = {
  selectedColumns: selectedTransactionCols,
  fixedSize: null,
  includeAllRows: false,
  filterByBands: true,
  includeDownloadButton: true,
  compress: false
};
